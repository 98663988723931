import i18next from "i18next";
import { useEffect, useState } from "react";
import React from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import Filter from "../../components/book/Filter";
import ListItem from "../../components/book/ListItem";

import MiniLoading from "../../components/MiniLoading";
import { get_books } from "../../services/bookApi";
import { Button } from "react-bootstrap";


const List = (props) => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const {sort, category} = useParams();

  const fetch_data = async (params) => {
    let p = props.match.params;
    setLoading(true);
    await get_books(p.sort, p.category, page).then(
      (res) => {
        setBooks(res);
        setLoading(false);
      }
    );
  };



  useEffect(() => {
    const params = props.match.params;

    var query = new URLSearchParams(props.location.search);
    if (query.has('page')) {
      setPage(query.get('page'));
    } else {
      setPage(1);
    }

    if (params.sort === undefined || params.category === undefined) {
      history.push("/books/new/0");
      
    } else {
      fetch_data(params);
    }
  }, [props]);
  return (
    <div className="container">
      <div id="books">
        <div className="row">
          <div className="col-xs-12">
            <h3 className="title">{i18next.t("books")}</h3>
          </div>
          <div className="col-xs-12 col-md-3">
            <Filter
              sort={props.match.params.sort}
              category={props.match.params.category}
            />
          </div>
          <div className="col-xs-12 col-md-9">
            <div className="row">
              {loading ? (
                <MiniLoading />
              ) : (
                books.data &&
                books.data.map((book, index) => (
                  <div className="col-xs-12 col-md-3" key={index}>
                    <ListItem item={book} />
                  </div>
                ))
              )}
            </div>

            <div className="pagination">
              <Link className="btn btn-primary" to={{
                pathname: "/books/" + sort  + "/" + category,
                search: "?page=" + (parseInt(page) > 1 ? parseInt(page) - 1:page),
                
              }}>
                {i18next.t("prev")}
              </Link>

              <span>
                {books.current_page}/{books.last_page}
              </span>
              <Link className="btn btn-primary" to={{
                pathname: "/books/" + sort  + "/" + category,
                search: "?page=" + (page< books.last_page ? parseInt(page) + 1:page),
                
              }}>
                {i18next.t("next")}
              </Link>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;

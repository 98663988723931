import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Context } from "..";
import { registration, schools } from "../services/userApi";
import { Button, Card, Form, Modal } from "react-bootstrap";
import swal from "@sweetalert/with-react";
import i18next from "i18next";

const Register = observer((props) => {
  const { user } = useContext(Context);
  const [first_name, setFirstName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirm_password, setConfirmPassword] = useState(null);
  const [school, setSchool] = useState(null);
  const [class_level, setClassLevel] = useState(5);
  const [school_data, setSchoolData] = useState([]);
  const [errors, setErrors] = useState({});

  const SignUpFn = async () => {
    await registration({
      first_name,
      last_name,
      email,
      phone,
      password,
      password_confirmation: confirm_password,
      school,
      class_level,
    })
      .then((res) => {
        if (res.success === true) {
          localStorage.setItem("token", res.data.token);
          user.setIsAuth(true);
          user.setUser(email);
          props.reset();
        } else {
          swal(i18next.t(res.message), "", "error");
        }
      })
      .catch((err) => {
        setErrors(err.response.data.errors);
        swal(i18next.t(err.response.data.message), "", "error");
      });
  };
  useEffect(() => {
    schools().then((res) => setSchoolData(res));
  }, []);
  return (
    <div className="mt-2">
      <Card className="w-50 p-3" style={{ margin: "auto" }}>
        <Card.Title className="title title-modal w-100">
          {i18next.t("Sign Up")}
        </Card.Title>

        <Card.Body className="d-grid gap-2 ">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder={i18next.t("first_name")}
              className={errors.first_name ? "is-invalid" : ""}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder={i18next.t("last_name")}
              className={errors.last_name ? "is-invalid" : ""}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="email"
              placeholder={i18next.t("email")}
              className={errors.email ? "is-invalid" : ""}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder={i18next.t("phone")}
              className={errors.phone ? "is-invalid" : ""}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="password"
              placeholder={i18next.t("password")}
              className={errors.password ? "is-invalid" : ""}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="password"
              placeholder={i18next.t("confirm_password")}
              className={errors.confirm_password ? "is-invalid" : ""}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="select"
              onChange={(e) => setSchool(e.target.value)}
            >
              <option>{i18next.t("select school")}</option>
              {school_data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Form.Control>
            <Form.Text className="text-muted">
              {i18next.t("school_text")}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Control
              as="select"
              onChange={(e) => setClassLevel(e.target.value)}
            >
              <option>{i18next.t("class level")}</option>
              {[5, 6, 7, 8, 9, 10, 11].map((e) => (
                <option key={e} value={e} selected={e == class_level}>
                  {e}
                </option>
              ))}
            </Form.Control>
            <Form.Text className="text-muted">
              {i18next.t("class_level_text")}
            </Form.Text>
          </Form.Group>

          <Button
            className="button-success  w-100 "
            variant="success"
            onClick={() => {
              SignUpFn();
            }}
          >
            {i18next.t("Sign Up")}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
});

export default Register;

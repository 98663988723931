import { $authHost } from "./index";

export const buy_quiz_get_token = async ({}) => {
  const { data } = await $authHost.post("/creative/buy-quiz", {});
  return data;
};

export const get_creative_user_quiz = async (token) => {
  const { data } = await $authHost.get("/creative/user-quiz/" + token);
  return data;
};

export const get_creative_user_quiz_item_questions = async (token, uqi_id) => {
  const { data } = await $authHost.get(
    "/creative/user-quiz-item-question/" + token + "/" + uqi_id
  );
  return data;
};

export const get_creative_user_quiz_item_question = async (id) => {
  const { data } = await $authHost.get("/creative/user-quiz/question/" + id);
  return data;
};

export const get_creative_user_quiz_item_detail = async (id) => {
  const { data } = await $authHost.get("/creative/ubt-exam-result/" + id);
  return data;
};

export const post_question_answer = async (id, is_checked) => {
  const { data } = await $authHost.post(
    "/creative/user-quiz/question-answer/" + id + "/" + is_checked
  );
  return data;
};

export const post_finish_quiz = async (token) => {
  const { data } = await $authHost.post("/creative/user-quiz/finish/" + token);
  return data;
};

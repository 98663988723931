import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router";
import { get_ubt_user_quiz_item_detail } from "../../../services/ubtApi";

import Account from "../Account";
import parse from "html-react-parser";

const UbtExamResult = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    data: [],
    quiz_item: {},
  });
  const params = useParams();
  useEffect(() => {
    setLoading(true);
    get_ubt_user_quiz_item_detail(params.item_id).then((res) => {
      setData(res);
      setLoading(false);
    });
  }, []);

  return (
    <Fragment>
      <div className="main-title">{i18next.t("ubt")}</div>
      <div id="UbtExamResult" className="container">
        <h3 className="title">{i18next.t("ubt_exams")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="exams" />
          </div>
          <div className="col-xs-12 col-md-8" id="quiz">
            {data.data.map((v, index) => {
              return (
                <div className="question mb-2" key={index}>
                  <div className="q">
                    {index + 1}.{" "}
                    {v.question.content && parse(v.question.content)}
                  </div>
                  <div className="answer">
                    <ul>
                      {v.answer
                        .filter((i) => i.col != 1)
                        .map((a, ii) => (
                          <Fragment>
                            <li
                              className={
                                a.is_checked === 1 && a.cc === 1
                                  ? "correct"
                                  : a.is_checked == 1
                                  ? "wrong"
                                  : ""
                              }
                            >
                              <i className="bi-record-circle-fill"></i>
                              {a.content && parse(a.content)}
                            </li>
                            {v.answer
                              .filter((i) => i.col == 1 && i.id == a.answer_id)
                              .map((aa, iii) => (
                                <li style={{ marginLeft: "15px" }}>
                                  {aa.content && parse(aa.content)}
                                </li>
                              ))}
                          </Fragment>
                        ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default UbtExamResult;

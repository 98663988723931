import React, { Fragment, useState } from "react";
import Account from "./Account";
import { change_password } from "../../services/userApi";

import i18next from "i18next";
import { Alert, Button, Card, Form } from "react-bootstrap";

const ChangePassword = () => {
  const [current_password, setCurrentPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [repeat_password, setRepeatPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    change_password({ current_password, new_password, repeat_password })
      .then((resp) => {
        if (resp.status == "error") {
          setError(resp.message);
        }
        if (resp.status == "success") {
          setError(null);
          setSuccess(resp.message);
          setCurrentPassword("");
          setNewPassword("");
          setRepeatPassword("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Fragment>
      <div className="main-title">{i18next.t("Change Password")}</div>
      <div id="Charge" className="container">
        <h3 className="title">{i18next.t("Change Password")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="change-password" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row justify-content-center">
              <Card className="w-50 m-0 p-0">
                <Card.Header>{i18next.t("Change Password")}</Card.Header>
                <Card.Body>
                  {error && <Alert variant="warning">{error}</Alert>}
                  {success && <Alert variant="success">{success}</Alert>}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="current_password">
                      <Form.Label>{i18next.t("current password")}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={i18next.t("current password")}
                        onChange={(event) =>
                          setCurrentPassword(event.target.value)
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="new_password">
                      <Form.Label>{i18next.t("new password")}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={i18next.t("new password")}
                        onChange={(event) => setNewPassword(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="repeat_password">
                      <Form.Label>{i18next.t("repeat password")}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={i18next.t("repeat password")}
                        onChange={(event) =>
                          setRepeatPassword(event.target.value)
                        }
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      {i18next.t("repeat password")}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ChangePassword;

import i18next from "i18next";
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useState } from "react";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Context } from "..";
import { login } from "../services/userApi";
import { Link } from "react-router-dom/cjs/react-router-dom";
import swal from "sweetalert";
const SignInForm = observer((props) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const { user } = useContext(Context);
  const auth = () => {
    login(email, password)
      .then((res) => {
        if(res.status == "error"){
          swal(i18next.t(res.message));
        }else{
          user.setIsAuth(true);
          user.setUser(res.email);
          props.reset();
        }
      })
      .catch((err) => console.log(err.response, "asdasd"));
  };
  // useEffect(() => {
  //   check().then((res) => {
  //     user.setIsAuth(true);
  //     props.reset();
  //   });
  // }, [props, user]);
  return (
    <Fragment>
      <Modal.Header className="flex-column">
        <img src="/logo.png" alt="..." className="logo-modal " />
        <Modal.Title className="title title-modal w-100">
          {i18next.t("SignIn")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-grid gap-2 ">
        <Form>
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              id="email"
              placeholder={i18next.t("email")}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="password"
              id="password"
              placeholder={i18next.t("password")}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Form>
        <Link to="/auth/forgot-password">{i18next.t("Forget password")}</Link>
        <Button
          className="button-success  w-100 "
          variant="success"
          onClick={() => {
            auth();
          }}
        >
          {i18next.t("SignIn")}
        </Button>
      </Modal.Body>
    </Fragment>
  );
});
export default SignInForm;

import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get_categories } from "../../services/bookApi";
import i18next from "i18next";
import MiniLoading from "../MiniLoading";

const Filter = (props) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort_by] = useState([
    {
      val: "new",
      text: i18next.t("new_books"),
    },
    {
      val: "discount",
      text: i18next.t("discount_books"),
    },
    {
      val: "featured",
      text: i18next.t("featured_books"),
    },
  ]);
  const fetch_book_categories = async () => {
    setLoading(true);
    await get_categories().then((res) => {
      setCategories(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    if(categories.length == 0){
      fetch_book_categories();
    }

  }, [props]);
  return (
    <Fragment>
      <h3 className="filter-title">{i18next.t("sort_by")}</h3>

      <ul className="filter-list">
        {sort_by.map((c, index) => (
          <li key={index}>
            <Link to={"/books/" + c.val + "/" + props.category + "?page=1" }>
              {c.val === props.sort ? (
                <b>
                  <i className="bi-circle-fill"></i> {c.text}
                </b>
              ) : (
                <span>
                  <i className="bi-circle"></i> {c.text}
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>

      <h3 className="filter-title">{i18next.t("Category")}</h3>
      <ul className="filter-list">
        <li>
          <Link to={"/books/" + props.sort + "/0?page=1"}>
            {props.category!=0 ? (
              <span>
                <i className="bi-circle"></i> {i18next.t("All Category")}
              </span>
            ) : (
              <b>
                <i className="bi-circle-fill"></i> {i18next.t("All Category")}
              </b>
            )}
          </Link>
        </li>
        {loading ? (
          <MiniLoading />
        ) : (
          categories.map((c, index) => (
            <li key={index}>
              <Link to={"/books/" + props.sort + "/" + c.id + "?page=1"}>
                {c.id === parseInt(props.category) ? (
                  <b>
                    <i className="bi-circle-fill"></i> {c.name}
                  </b>
                ) : (
                  <span>
                    <i className="bi-circle"></i> {c.name} 
                  </span>
                )}
              </Link>
            </li>
          ))
        )}
      </ul>
    </Fragment>
  );
};
export default Filter;

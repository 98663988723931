import i18next from "i18next";
import { Fragment, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";

const Static = () => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 col-md-4">
          <a href={i18next.t("statistic_1_link")} className="box-link">
            <div className="card">
              <i className="mini-logo">
                <img src="/mini_logo.png" alt="qazbilim" />
              </i>
              <h4>{i18next.t("statistic_1")}</h4>
              <p>{i18next.t("statistic_1_text")}</p>
            </div>
          </a>
        </div>
        <div className="col-xs-12 col-md-4">
          <a href={i18next.t("statistic_2_link")} className="box-link">
            <div className="card">
              <i className="mini-logo">
                <img src="/mini_logo.png" alt="qazbilim" />
              </i>
              <h4>{i18next.t("statistic_2")}</h4>
              <p>{i18next.t("statistic_2_text")}</p>
            </div>
          </a>
        </div>

        <div className="col-xs-12 col-md-4">
          <a href={i18next.t("statistic_3_link")} className="box-link">
            <div className="card">
              <i className="mini-logo">
                <img src="/mini_logo.png" alt="qazbilim" />
              </i>
              <h4>{i18next.t("statistic_3")}</h4>
              <p>{i18next.t("statistic_3_text")}</p>
            </div>
          </a>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xs-12 col-md-4">
          <a href={i18next.t("statistic_4_link")} className="box-link">
            <div className="card">
              <i className="mini-logo">
                <img src="/mini_logo.png" alt="qazbilim" />
              </i>
              <h4>{i18next.t("statistic_4")}</h4>
              <p>{i18next.t("statistic_4_text")}</p>
            </div>
          </a>
        </div>
        <div className="col-xs-12 col-md-4">
          <a href={i18next.t("statistic_5_link")} className="box-link">
            <div className="card">
              <i className="mini-logo">
                <img src="/mini_logo.png" alt="qazbilim" />
              </i>
              <h4>{i18next.t("statistic_5")}</h4>
              <p>{i18next.t("statistic_5_text")}</p>
            </div>
          </a>
        </div>
        <div className="col-xs-12 col-md-4">
          <a href={i18next.t("statistic_6_link")} className="box-link">
            <div className="card">
              <i className="mini-logo">
                <img src="/mini_logo.png" alt="qazbilim" />
              </i>
              <h4>{i18next.t("statistic_6")}</h4>
              <p>{i18next.t("statistic_6_text")}</p>
            </div>
          </a>
        </div>
      </div>
    </Fragment>
  );
};

const StaticCourse = () => {
  const data = [
    {
      id: 1,
      image: i18next.t("course_image_1"),
      title: i18next.t("course_title_1"),
      descr: i18next.t("course_descr_1"),
      link: i18next.t("course_link_1"),
    },
    {
      id: 2,
      image: i18next.t("course_image_2"),
      title: i18next.t("course_title_2"),
      descr: i18next.t("course_descr_2"),
      link: i18next.t("course_link_2"),
    },
    {
      id: 3,
      image: i18next.t("course_image_3"),
      title: i18next.t("course_title_3"),
      descr: i18next.t("course_descr_3"),
      link: i18next.t("course_link_3"),
    },
  ];

  const [sitem, setSitem] = useState({});

  return (
    <section id="home-static-course" className="bg-gray">
      <div className="container">
        <Static />
        <div className="row">
          <div className="col-xs-12">
            <h3 className="title">{i18next.t("online_course")}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="d-flex acc">
              {data.map((item, index) => (
                <Fragment key={index}>
                  <div
                    className={
                      "flex-fill info " +
                      (item.id === sitem.id
                        ? "d-block fade-in-right"
                        : index === 0 && sitem.id === undefined
                        ? "d-block fade-in-right"
                        : "d-none")
                    }
                  >
                    <div className="row">
                      <div
                        className="col-xs-12 col-md-6 item-bg d-none d-md-block"
                        style={{
                          backgroundImage: "url(/images/C_Kanban_Board.png)",
                        }}
                      ></div>
                      <div className="col-xs-12 col-md-6">
                        <div className="card">
                          <i className="bi-pencil"></i>
                          <h4>{item.title}</h4>
                          <p>{item.descr}</p>
                          <Link to={item.link} className="btn btn-sm btn-white">
                            {i18next.t("read_more")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "flex-fill item" + (item.id === sitem.id ? " active" : "")
                    }
                    onClick={() => setSitem(item)}
                  >
                    <h4>
                      {item.title} <span>0{item.id}</span>
                    </h4>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StaticCourse;

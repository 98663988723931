import { Modal, Table } from "react-bootstrap";
import React, { Fragment, useContext, useEffect, useState } from "react";

import SignInForm from "../modals/SignInForm";

import { Link } from "react-router-dom";
import i18next from "i18next";
import { observer } from "mobx-react-lite";
import { Context } from "..";
import { check, unfinished, get_cart } from "../services/userApi";
import SignUp from "../modals/SignUp";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const Header = observer(() => {
  const { user } = useContext(Context);
  const [show, setShow] = useState(false);
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [verify, setVerify] = useState(false);
  const [forget, setForgot] = useState(false);
  const [cart, setCart] = useState([]);
  const [menu, setMenu] = useState(false);
  const history = useHistory();
  const [unfinished_data, setUnfinishedData] = useState({
    ubt: [],
    nzm: [],
    bil: [],
    lvl: [],
    week: [],
    accept: [],
  });
  const [show_unfinished, setShowUnfinished] = useState(false);
  const { pathname } = useLocation();
  useEffect(async () => {
    setMenu(false);
    await check()
      .then((res) => {
        user.setUser(true);
        user.setIsAuth(true);
        check_unfinished();
        check_cart();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // setShow(true);
          // setLogin(true);
          user.setUser(null);
          user.setIsAuth(false);
          localStorage.removeItem("token");
        }
      });
  }, []);

  const check_cart = () => {
    get_cart().then((res) => {
      // setCart(res);
      user.setCartCount(res.length);
    });
  };
  const check_unfinished = () => {
    if (pathname == "/") {
      unfinished().then((res) => {
        setUnfinishedData(res);
        setShowUnfinished(true);
      });
    }
  };
  const logout = () => {
    user.setUser(null);
    user.setIsAuth(false);
    localStorage.removeItem("token");
    window.location.href = "/";
  };
  const menuClick = (page) => {
    setMenu(false);
    // history.push(page);
  };
  return (
    <Fragment>
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 d-none d-sm-block">
              <ul className="top-address">
                <li className="d-none d-sm-block">
                  <i className="bi-geo-alt"></i>
                </li>
                <li>
                  <i className="bi-envelope"></i> {i18next.t("email")}
                </li>
                <li>
                  <i className="bi-phone"></i> {i18next.t("phone")}
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-md-6">
              <ul className="top-social">
                {user.IsAuth() ? (
                  <Fragment>
                    <li className="cart" style={{ marginRight: 1 }}>
                      <Link to="/user/cart" className="cart">
                        <i className="bi-cart"></i> {user.getCartCount()}
                      </Link>
                    </li>
                    <li className="login" style={{ marginRight: 1 }}>
                      <Link to="/user/profile">
                        <i className="bi-person-circle"></i>{" "}
                        <span className="d-none d-md-block">{i18next.t("Profile")}</span>
                      </Link>
                    </li>
                    <li
                      className="login"
                      onClick={() => {
                        logout();
                      }}
                    >
                      <i className="bi-x-circle"></i> <span className="d-none d-md-block">{i18next.t("Logout")}</span>
                    </li>
                  </Fragment>
                ) : (
                  <Fragment>
                    <li
                      className="login"
                      style={{ marginRight: 1 }}
                      onClick={() => {
                        setShow(true);
                        setLogin(true);
                      }}
                    >
                      {i18next.t("SignIn")}
                    </li>
                    <li
                      className="login"
                      onClick={() => {
                        setShow(true);
                        setLogin(false);
                        setRegister(true);
                      }}
                    >
                      {i18next.t("SignUp")}
                    </li>
                  </Fragment>
                )}

                <li>
                  <a href="http://facebook.com">
                    <i className="bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="http://google.com">
                    <i className="bi-google"></i>
                  </a>
                </li>
                <li>
                  <a href="http://instagram.com">
                    <i className="bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src="/logo.png" alt="..." className="logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setMenu(!menu)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={
                menu
                  ? "collapse navbar-collapse show"
                  : "collapse navbar-collapse"
              }
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {/* <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                  Басты бет
                  </a>
                </li> */}

                <li className="nav-item">
                  <Link
                    to="/course"
                    className="nav-link"
                    onClick={() => menuClick("/course")}
                  >
                    {i18next.t("online_course")}
                  </Link>
                </li>
                <li className="nav-item">

                  <Link
                    to="/ubt"
                    className="nav-link" onClick={() => menuClick("/ubt")}>
                    {i18next.t("ubt")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/nzm" className="nav-link" onClick={() => menuClick("/nzm")}>
                    {i18next.t("nzm")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  to="/bil" className="nav-link" onClick={() => menuClick("/bil")}>
                    {i18next.t("bil")}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                  to="/level"
                    className="nav-link"
                    onClick={() => menuClick("/level")}
                  >
                    {i18next.t("level")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  to="/books/new"
                    className="nav-link"
                    onClick={() => menuClick("/books/new")}
                  >
                    {i18next.t("books")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  to="/week" className="nav-link" onClick={() => menuClick("/week")}>
                    {i18next.t("week_quiz")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  to="/accept"
                    className="nav-link"
                    onClick={() => menuClick("/accept")}
                  >
                    {i18next.t("accept_quiz")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  to="/creative"
                    className="nav-link"
                    onClick={() => menuClick("/creative")}
                  >
                    {i18next.t("creative")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                  to="/top25"
                    className="nav-link"
                    onClick={() => menuClick("/top25")}
                  >
                    {i18next.t("top25")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Modal
          show={show_unfinished}
          size="md"
          onHide={() => {
            setShowUnfinished(false);
          }}
        >
          <Modal.Header className="flex-column">
            {i18next.t("Unfinished")}
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th colSpan={3}> {i18next.t("ubt")}</th>
                </tr>
                {unfinished_data.ubt.map((e) => (
                  <tr key={e.id}>
                    <td>{e.id}</td>
                    <td>{e.token}</td>
                    <td>
                      <Link to={"/ubt/quiz/" + e.token}>
                        {i18next.t("continue")}
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3}> {i18next.t("nzm")}</th>
                </tr>
                {unfinished_data.nzm.map((e) => (
                  <tr key={e.id}>
                    <td>
                       {e.id}
                    </td>
                    <td>{e.token}</td>
                    <td>
                      <Link to={"/bnl/quiz/nzm/" + e.token}>
                        {i18next.t("continue")}
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3}> {i18next.t("bil")}</th>
                </tr>
                {unfinished_data.bil.map((e) => (
                  <tr key={e.token}>
                    <td>
                      {e.id}
                    </td>
                    <td>{e.token}</td>
                    <td>
                      <Link to={"/bnl/quiz/bil/" + e.token}>
                        {i18next.t("continue")}
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3}> {i18next.t("lvl")}</th>
                </tr>
                {unfinished_data.lvl.map((e) => (
                  <tr key={e.token}>
                    <td>{e.class_name}</td>
                    <td>{e.lesson_name}</td>
                    <td>
                      <Link to={"/level/quiz/" + e.token}>
                        {i18next.t("continue")}
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3}> {i18next.t("week")}</th>
                </tr>
                {unfinished_data.week.map((e) => (
                  <tr key={e.token}>
                    <td>{e.class_name}</td>
                    <td>{e.lesson_name}</td>
                    <td>
                      <Link to={"/week/quiz/" + e.token}>
                        {i18next.t("continue")}
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr>
                  <th colSpan={3}> {i18next.t("accept")}</th>
                </tr>
                {unfinished_data.accept.map((e) => (
                  <tr key={e.token}>
                    <td>{e.class_name}</td>
                    <td>{e.lesson_name}</td>
                    <td>
                      <Link to={"/accept/quiz/" + e.token}>
                        {i18next.t("continue")}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
        <Modal
          show={show}
          size="md"
          onHide={() => {
            setShow(false);
            setLogin(false);
            setRegister(false);
            setVerify(false);
            setForgot(false);
          }}
        >
          {login ? (
            <SignInForm
              reset={() => {
                setShow(false);
                setLogin(false);
              }}
            />
          ) : register ? (
            <SignUp
              reset={() => {
                setShow(false);
                setRegister(false);
              }}
            />
          ) : (
            ""
          )}

          <Modal.Footer className="justify-content-center">
            <a href="http://facebook.com">
              <i className="bi-facebook p-2"></i>
            </a>
            <a href="http://instagram.com">
              <i className="bi-instagram p-2"></i>
            </a>
            <a href="https://twitter.com/">
              <i className="bi bi-twitter p-2"></i>
            </a>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
});

export default Header;

import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React    from 'react';
import ListItem from "../../components/book/ListItem";
import MiniLoading from "../../components/MiniLoading";
import { my_books } from "../../services/userApi";
import Account from "./Account";

const Books = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetch_my_books = async () => {
    setLoading(true);
    await my_books().then((res) => {
      setData(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch_my_books();
  }, []);
  return (
    <Fragment>
      <div className="main-title">{i18next.t("profile")}</div>
      <div id="books" className="container">
        <h3 className="title">{i18next.t("my_books")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="books" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              {loading ? (
                <MiniLoading />
              ) : (
                data.map((book, index) => (
                  <div className="col-xs-12 col-md-3" key={index}>
                    <ListItem item={book} is_paid="true" />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Books;

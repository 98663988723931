import { Button } from "bootstrap";
import { useState } from "react";
import { Card, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import React from "react";
import i18next from "i18next";
import swal from "@sweetalert/with-react";
import { reset_password } from "../services/userApi";

const ResetForm = (props) => {
  const [password, setPassword] = useState("");
  const [repeat_password, setRepeatPassword] = useState("");
  const [error, setError] = useState(null);
  const query = new URLSearchParams(props.location.search);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      password != "" &&
      repeat_password != "" &&
      password == repeat_password
    ) {
      reset_password({ password, token: query.get("token") }).then((res) => {
        swal(i18next.t(res.message));
        if (res.status == "success") {
          setTimeout(function () {
            window.location.href = "/";
          }, 1500);
        }
      });
    } else {
      swal(i18next.t("repeat password not match"));
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md="4">
          <Card>
            <Card.Header> {i18next.t("Set new password")}</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Form.Label htmlFor="new_password">
                    {i18next.t("new password")}:
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="new_password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Label htmlFor="repeat_password">
                    {i18next.t("repeat password")}:
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="repeat_password"
                    value={repeat_password}
                    onChange={(event) => setRepeatPassword(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className="mt-3">
                  <button type="submit" className="btn btn-primary">
                    {i18next.t("Send password reset email")}
                  </button>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default ResetForm;

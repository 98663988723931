import React, { useState } from "react";

function Footer() {
  const [address] = useState([
    {
      name: "Астана қаласы №1",
      address: "Қаныш Сәтбаев, 22/1",
      phone: "+7775 431 8008",
    },
    {
      name: "Астана қаласы №2",
      address: "Сарыарқа 31/2",
      phone: "+7701 751 8008",
    },
    {
      name: "Астана қаласы №3",
      address: "Ә. Бөкейхан 17/1",
      phone: "+7701 941 8008",
    },
    {
      name: "Алматы қаласы №1",
      address: "Мамыр-4 шағын ауданы, 144А",
      phone: "+7701 731 8008",
    },
    {
      name: "Алматы қаласы №2",
      address: "М. Төлебаев 38",
      phone: "+7701 751 8008",
    },
    {
      name: "Шымкент қаласы",
      address: "Д. Қонаев 39/1",
      phone: "+7701 761 8008",
    },
    {
      name: "Ақтау қаласы",
      address: "29-шағын аудан,Толқын-2, 121/2",
      phone: "+7701 762 8008",
    },
    {
      name: "Қызылжар (Петропавл) қаласы",
      address: "Н. Назарбаев 69А",
      phone: "+7701 752 8008",
    },

    {
      name: "Қарағанды қаласы",
      address: "Құрылысшылар 4 ",
      phone: "+7701 973 8008",
    },

    {
      name: "Семей қаласы",
      address: "Қабанбай батыр 128",
      phone: "+7701 972 8008",
    },

    {
      name: "Қызылорда қаласы",
      address: "Ә. Бөкейхан 146",
      phone: "+7701 934 8008",
    },

    {
      name: "Атырау қаласы",
      address: "С. Бейбарыс даңғылы 458",
      phone: "+7701 732 8008",
    },

    {
      name: "Қостанай қаласы",
      address: "5 декабрь 100А",
      phone: "+7701 754 8008",
    },

    {
      name: "QAZBILIM Onlinе",
      address: "Онлайн бөлімі",
      phone: "+7707 831 8008",
    },
    {
      name: "QAZBILIM Onlinе",
      address: "QAZBILIM платформасы",
      phone: "+7707 431 8008",
    },
  ]);
  return (
    <div id="footer" className="mt-5 pt-5">
      <div className="container">
        <h3 className="title-footer mb-4">Байланыс</h3>
        <div className="row">
          {address.map((item, index) => (
            <div key={index} className="col-xs-12 col-md-4 text-center mb-3">
              <h5>{item.name}</h5>
              <p>{item.address}</p>
              <p>{item.phone}</p>
            </div>
          ))}
        </div>
        <hr />
        <p className="mt-3">© Qazbilim Group, 2023 жыл</p>
      </div>
    </div>
  );
}

export default Footer;

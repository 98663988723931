import { Link } from "react-router-dom";
import { REACT_APP_API_URL } from "../../services/";
import React, { Fragment } from "react";
import i18next from "i18next";

function Item(props) {
  //const max_rate = 5;
  const curr_rate = 3.5; //1.3
  const fill_rate = curr_rate | 0; //1
  const half_rate = curr_rate - fill_rate; //1.3-1 = 0.3
  const empty_rate = 5 - fill_rate - (half_rate > 0 ? 1 : 0); //5-1-1=3 (f h e e e)
  return (
    <div className="book-item mb-4">
      {props.item.is_new ? (
        <span className="label status">{i18next.t("new")}</span>
      ) : (
        ""
      )}
      {props.item.discount > 0 ? (
        <span className="label discount">
          {" "}
          {props.item.discount} {i18next.t("tenge")}
        </span>
      ) : (
        ""
      )}
      <div className="action-cover  align-content-center flex-wrap">
        {props.is_paid ? (
          ""
        ) : (
          <Fragment>
            <button className="btn btn-info">
              <i className="bi-heart"></i>
            </button>
            <button className="btn btn-info">
              <i className="bi-cart"></i>
            </button>
          </Fragment>
        )}

        <Link className="btn btn-info" to={"/book-detail/" + props.item.id}>
          <i className="bi bi-link-45deg"></i>
        </Link>
      </div>
      <div
        className="img img-h1"
        style={{
          backgroundImage: `url(${REACT_APP_API_URL}${props.item.cover_path})`,
        }}
      ></div>

      <Link to={"/book-detail/" + props.item.id}>
        <div className="name line">{props.item.title}</div>
      </Link>
      <Link to={"/book-detail/" + props.item.id}>
        <div className="author">{props.item.author}</div>
      </Link>
      {props.item.price ? <div className="price">{props.item.price}₸</div> : ""}
    </div>
  );
}

export default Item;

import { $authHost, $host } from ".";

export const get_lastest_books = async () => {
  const { data } = await $host.get("/book/latest-books");
  return data;
};

export const get_book = async (id) => {
  const { data } = await $authHost.get("/book/book/" + id);
  return data;
};

export const get_categories = async () => {
  const { data } = await $host.get("/book/categories");
  return data;
};

export const get_books = async (sort, category, page = 0) => {
  const { data } = await $host.get(
    "/book/books/" + sort + (category ? "/" + category : "/0") + "?page=" + page
  );
  return data;
};

export const book_buy_book = async (id, order_type, form) => {
  const { data } = await $authHost.post(
    "/buy-book/" + id + "/" + order_type,
    form
  );
  return data;
};

export const book_pages = async (id) => {
  const { data } = await $authHost.post("/download-book/" + id + "/full");
  return data;
};

export const get_book_content = async (id, page) => {
  const { data } = await $authHost.post(
    "/download-book/" + id + "/full/" + page
  );
  return data;
};

export const book_delivery = async (id, form) => {
  const { data } = await $authHost.post("/book-delivery/" + id, form);
  return data;
};

export const book_download_book = async (id, file_name) => {
  const { data } = await $host.get("/download-book/" + id + "/" + file_name, {
    responseType: "blob",
  });
  return data;
};

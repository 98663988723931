import React  from 'react';
const MiniLoading = () => {
  return (
    <div className="mini-loading">
      <div className="indicator">
        <div className="loader"></div>
        <img src="/logo.png" alt="Loading..." className="logo"></img>
      </div>
    </div>
  );
};

export default MiniLoading;

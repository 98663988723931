import i18next from "i18next";
import { useEffect, useState } from "react";
import React    from 'react';
import { newses } from "../../services/publicApi";
import { REACT_APP_API_URL } from "../../services";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const NewsList = () => {
  const [data, setData] = useState([]);
  const fetch_news = async () => {
    await newses().then((res) => setData(res));
  };
  useEffect(() => {
    fetch_news();
  }, []);
  return (
    <div className="container" id="news">
      <div className="row">
        <div className="col-xs-12">
          <h3>{i18next.t("newses")}</h3>
        </div>
        {data.data &&
          data.data.map((item, index) => (
            <div className="col-xs-12 col-md-3">
              <div className="news-item">
                <Link to={"/news/" + item.id}>
                  <div
                    className="cover"
                    style={{
                      backgroundImage: `url(${REACT_APP_API_URL}${item.cover_path})`,
                    }}
                  ></div>
                </Link>

                <div className="title-news">
                  <Link to={"/news/" + item.id}>{item.title}</Link>
                </div>

                <div className="short-news">
                  <Link to={"/news/" + item.id}>{parse(item.short)}</Link>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default NewsList;

import i18next from "i18next";
import { Fragment, useContext, useEffect, useState } from "react";
import React from "react";
import UbtMainLesson from "../../components/ubt/UbtMainLesson";
import { get_ubt_lessons } from "../../services/publicApi";

import swal from "@sweetalert/with-react";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import { buy_quiz_get_token } from "../../services/creativeApi";

const Creative = observer(() => {
    const { user } = useContext(Context);
    const [items, setItems] = useState([]);

    const [firstLesson, setFirstLesson] = useState(null);
    const [secondLesson, setSecondLesson] = useState(null);



    useEffect(() => {
        fetch_data();
    }, []);

    const fetch_data = async () => {
        await get_ubt_lessons().then(async (res) => {
            await setItems(res);
        });
    };


    const buy_quiz = () => {
        if (!user.IsAuth()) {
            swal(
                i18next.t("first login"),
                {
                    buttons: {
                        ok: i18next.t("Ok"),
                    },
                },
                "warning"
            );
        }
        if (user.IsAuth()) {
            buy_quiz_get_token({}).then((res) => {
                if (res.status === "error") {
                    swal(i18next.t("error"), res.message, "error");
                }
                if (res.status === "success") {
                    window.location.href = "/#/creative/quiz/" + res.token;
                }
            });
        }
    };

    return (
        <div id="ubt" className="bg-gray pb-5">
            <div className="main-title">{i18next.t("creative_title")}</div>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h3 className="title">{i18next.t("buy_creative_quiz")}</h3>
                    </div>
                </div>
                <UbtMainLesson
                    data={items.filter((el) => el.lesson_type === "primary" && (el.id == 2 || el.id == 5))}
                />
                <div className="mt-3 text-center">

                    <div className="col-xs-12">
                        <div className="text-center">
                            <button className="btn btn-blue" onClick={() => buy_quiz()}>
                                {i18next.t("buy_quiz")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
export default Creative;

import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React    from 'react';
import Account from "../Account";
import { my_ubt_exams } from "../../../services/userApi";
import MiniLoading from "../../../components/MiniLoading";
import UbtExamItem from "./UbtExamItem";

const UbtExams = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetch_ubt_exams = async () => {
    setLoading(true);
    await my_ubt_exams().then((res) => {
      setData(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch_ubt_exams();
  }, []);
  return (
    <Fragment>
      <div className="main-title">{i18next.t("ubt")}</div>
      <div id="UbtExams" className="container">
        <h3 className="title">{i18next.t("ubt_exams")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="exams" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              {loading ? (
                <MiniLoading />
              ) : (
                data.map((v, i) => {
                  return <UbtExamItem key={i} item={v} />;
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default UbtExams;

import i18next from "i18next";
import { Link } from "react-router-dom";
import React from "react";

function Quiz() {
  return (
    <div className="container" id="quiz-home">
      <h3 className="title">{i18next.t("quizes")}</h3>
      <div className="row">
        <div className="col-xs-12">
          <div className="main-part">
            <div className="cpanel">
              <div className="icon-part">
                <i className="bi bi-people"></i>
                <br />
                <p className="ms-3 me-3">{i18next.t("ubt_full_name")}</p>
                <small>{i18next.t("ubt")}</small>
              </div>
              <div className="card-content-part">
                <Link to="/ubt">{i18next.t("More Detail")} </Link>
              </div>
            </div>

            <div className="cpanel cpanel-green">
              <div className="icon-part">
                <i className="bi bi-people" aria-hidden="true"></i>
                <br />
                <p className="ms-3 me-3">{i18next.t("nzm_full_name")}</p>
                <small>{i18next.t("nzm")}</small>
              </div>
              <div className="card-content-part">
                <Link to="/nzm">{i18next.t("More Detail")} </Link>
              </div>
            </div>

            <div className="cpanel cpanel-orange">
              <div className="icon-part">
                <i className="bi bi-people" aria-hidden="true"></i>
                <br />
                <p className="ms-3 me-3">{i18next.t("bil_full_name")}</p>
                <small>{i18next.t("bil")}</small>
              </div>
              <div className="card-content-part">
                <Link to="/bil">{i18next.t("More Detail")} </Link>
              </div>
            </div>
            <div className="cpanel cpanel-blue">
              <div className="icon-part">
                <i className="bi bi-people" aria-hidden="true"></i>
                <br />
                <p className="ms-3 me-3">{i18next.t("level_full_name")}</p>
                <small>{i18next.t("level")}</small>
              </div>
              <div className="card-content-part">
                <Link to="/bil">{i18next.t("More Detail")} </Link>
              </div>
            </div>
            <div className="cpanel cpanel-red">
              <div className="icon-part">
                <i className="bi bi-people" aria-hidden="true"></i>
                <br />
                <p className="ms-3 me-3">{i18next.t("week_full_name")}</p>
                <small>{i18next.t("week")}</small>
              </div>
              <div className="card-content-part">
                <Link to="/week">{i18next.t("More Detail")} </Link>
              </div>
            </div>
            <div className="cpanel cpanel-skyblue">
              <div className="icon-part">
                <i className="bi bi-people" aria-hidden="true"></i>
                <br />
                <p className="ms-3 me-3">{i18next.t("accept_full_name")}</p>
                <small>{i18next.t("accept")}</small>
              </div>
              <div className="card-content-part">
                <Link to="/accept">{i18next.t("More Detail")} </Link>
              </div>
            </div>
          </div>
          {/* <div className="row quiz-home">
            <div className="col-xs-12 col-md-3">
              <Link to="/ubt">
                <div
                  className="item-bg m-2"
                  style={{
                    backgroundImage: "url(/images/ubt_img.png)",
                  }}
                ></div>
                <h4> #1 {i18next.t("ubt_test")}</h4>
              </Link>
            </div>
            <div className="col-xs-12 col-md-3">
              <Link to="/nzm">
                <div
                  className="item-bg m-2"
                  style={{
                    backgroundImage: "url(/images/nzm_img.png)",
                  }}
                ></div>

                <h4>{i18next.t("nzm_test")}</h4>
              </Link>
            </div>
            <div className="col-xs-12 col-md-3">
              <Link to="/bil">
                <div
                  className="item-bg"
                  style={{
                    backgroundImage: "url(/images/bil_img.png)",
                  }}
                ></div>
                <h4>{i18next.t("bil_test")}</h4>
              </Link>
            </div>
            <div className="col-xs-12 col-md-3">
              <Link to="/lvl">
                <div
                  className="item-bg"
                  style={{
                    backgroundImage: "url(/images/lvl_img.png)",
                  }}
                ></div>
                <h4>{i18next.t("level_test")}</h4>
              </Link>
            </div>
          </div>
         */}
        </div>
      </div>
    </div>
  );
}

export default Quiz;

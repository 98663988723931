import { $authHost, $host } from ".";

export const get_lessons = async (lwa) => {
  const { data } = await $host.get("/lwa/lessons/" + lwa);
  return data;
};

export const get_lesson_classes = async (lwa, lesson) => {
  const { data } = await $host.get("lwa/lesson/classes/" + lesson + "/" + lwa);
  return data;
};

export const buy_quiz = async (lwa, lesson_class) => {
  const { data } = await $authHost.post("/lwa/buy/" + lwa + "/" + lesson_class);
  return data;
};

export const questions = async (lwa, token) => {
  console.log(lwa, token);
  const { data } = await $authHost.get("/lwa/questions/" + lwa + "/" + token);
  console.log(data)
  return data;
};

export const quiz_questions = async (lwa, user_quiz_id) => {
  console.log(lwa, user_quiz_id);
  const { data } = await $authHost.get("/lwa/user-quiz-questions/" + lwa + "/" + user_quiz_id);
  console.log(data)
  return data;
};

export const question = async (lwa, id) => {
  const { data } = await $authHost.get("/lwa/question/" + lwa + "/" + id);
  return data;
};

export const question_answer = async (lwa, id, is_true) => {
  const { data } = await $authHost.post(
    "/lwa/question-answer/" + lwa + "/" + id + "/" + is_true
  );
  return data;
};

export const finish_quiz = async (lwa, token) => {
  const { data } = await $authHost.post("/lwa/finish/" + lwa + "/" + token);
  return data;
};

export const user_quiz = async (lwa) => {
  const { data } = await $authHost.get("/lwa/user-quiz/" + lwa);
  return data;
};

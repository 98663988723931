import i18next from "i18next";

import { Fragment, useContext, useEffect, useState } from "react";
import React from "react";
import { useHistory } from "react-router-dom";
import swal from "@sweetalert/with-react";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import BnlLessonItem from "../../components/bnl/BnlLessonItem";
import MiniLoading from "../../components/MiniLoading";
import { buy_quiz, get_bnl_lessons } from "../../services/bnlApi";

const Bil = observer(() => {
  const { user } = useContext(Context);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inline_loading, setInlineLoading] = useState(false);
  const buy_quiz_action = () => {
    if (!user.IsAuth()) {
      swal(
        i18next.t("first login"),
        {
          buttons: {
            ok: i18next.t("Ok"),
          },
        },
        "warning"
      );
    }
    if (user.IsAuth()) {
      setInlineLoading(true);
      buy_quiz("bil")
        .then((res) => {
          setInlineLoading(false);
          if (res.status === "success") {
            history.push("/bnl/quiz/bil/" + res.token);
          }
          if (res.status === "error") {
            swal(
              i18next.t(res.message),
              {
                buttons: {
                  ok: i18next.t("Ok"),
                },
              },
              "warning"
            );
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            swal(
              i18next.t("first login"),
              {
                buttons: {
                  ok: i18next.t("Ok"),
                },
              },
              "warning"
            );
            setInlineLoading(false);
          }
        });
    }
  };
  const fetch_data = () => {
    setLoading(true);
    get_bnl_lessons("bil").then((res) => {
      setLoading(false);
      if (res.status === "error") {
        alert(i18next.t(res.message));
      } else {
        setData(res);
      }
    });
  };
  useEffect(() => {
    fetch_data();
  }, []);
  return (
    <Fragment>
      <div id="bnl" className="bg-gray">
        <div className="main-title">{i18next.t("bil exam")}</div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="title">{i18next.t("tur1")}</h3>
            </div>
            <div className="col-xs-12">
              <div className="row">
                {loading ? (
                  <MiniLoading />
                ) : (
                  data.map((item, index) =>
                    item.tur === 1 ? (
                      <div key={index} className="col-xs-12 col-md-6">
                        <BnlLessonItem item={item} />
                      </div>
                    ) : (
                      ""
                    )
                  )
                )}
              </div>
            </div>
            <div className="col-xs-12">
              <h3 className="title">{i18next.t("tur2")}</h3>
            </div>
            <div className="col-xs-12">
              <div className="row">
                {loading ? (
                  <MiniLoading />
                ) : (
                  data.map((item, index) =>
                    item.tur === 2 ? (
                      <div key={index} className="col-xs-12 col-md-6">
                        <BnlLessonItem item={item} />
                      </div>
                    ) : (
                      ""
                    )
                  )
                )}
              </div>
            </div>
            <div className="col-xs-12">
              <div className="text-center">
                <div className="text-center">
                  {inline_loading ? (
                    <button className="btn btn-secondary">
                      {i18next.t("loading...")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => buy_quiz_action()}
                    >
                      {i18next.t("buy now")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bnl">
            <h3>{i18next.t("about_bil_exam")}</h3>
            <p>{i18next.t("about_bil_exam_text")}</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
});
export default Bil;

import { useEffect, useState } from "react";
import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CountDown from "../../components/CountDown";
import parse from "html-react-parser";
import swal from "sweetalert";
import i18next from "i18next";
import {
  questions,
  question,
  question_answer,
  finish_quiz,
  quiz_questions,
} from "../../services/lwaApi";
import MiniLoading from "../../components/MiniLoading";
import { Alert, Badge } from "react-bootstrap";

const LVLQuiz = () => {
  const history = useHistory();
  const { token } = useParams();

  const [data, setData] = useState({ data: {} });
  const [item, setItem] = useState(null);
  const [questions_data, setQuestionData] = useState([]);
  const [loading, setLoading] = useState(false);

  const finish = () => {
    finish_quiz("level", token).then((res) => {
      window.location.href = "/#/user/exam/lvl/"+token;
    });
    // post_finish_quiz(unic_token[0], unic_token[1]).then((res) => {
    //   if (res.status === "success") {
    //     history.push("/user/quiz/bnl/bil/" + token);
    //   }
    // });
  };
  const submit_answer = (_item) => {
    let c = item.answers.reduce((total, i) => {
      return total + (i.is_checked ? 1 : 0);
    }, 0);
    let max_choice = item.question.max_choice;
    if (max_choice > c) {
      question_answer("level", _item.id, !_item.is_checked).then((res) => {
        if (res.status === "success") {
          fetch_questions(data.data.id);
          fetch_question(item.question.id);
        }
      });
    } else {
      if (_item.is_checked) {
        question_answer("level", _item.id, _item.is_checked ? 0 : 1).then(
          (res) => {
            if (res.status === "success") {
              fetch_questions(data.data.id);
              fetch_question(item.question.id);
            }
          }
        );
      } else {
        swal(i18next.t("Info"), i18next.t("max_choice"), "info");
      }
    }
  };
  const fetch_question = (qid) => {
    setLoading(true);
    question("level", qid).then((res) => {
      setItem(res);
      setLoading(false);
    });
  };

  const fetch_questions = (id) => {
    
    quiz_questions('level', id).then((res)=>{
      setQuestionData(res);
      if(item==null){
        fetch_question(res[0].id);
      }
    });
  }

  useEffect(() => {
    questions("level", token).then((res) => {
      setData(res);
      fetch_questions(res.data.id);
    });
  }, [token]);

  return (
    <div className="pt-5">
      <div className="container" id="quiz">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <h4 className="name-time">
              {/* {data} */}
              {data.data ? data.data.lesson_name:''} <i className="bi-dot" />{" "}
              {data.data ? data.data.class_name:''}
              <CountDown
                seconds={parseInt(data.seconds)}
                time_expired={() => finish()}
              />
            </h4>
            {(loading==true) ? (
              <MiniLoading />
            ) : item!=null ?(
              <div className="question">
                <h4>
                  {i18next.t("question")}: {item.question.id}
                </h4>
                <hr />
                <div className="q">{parse(" " + item.question.content)}</div>
                <div className="answer">
                  <ul>
                    {item.answers.map((item, index) => (
                      <li
                        key={index}
                        className={item.is_checked ? "check" : ""}
                        onClick={() => {
                          submit_answer(item);
                        }}
                      >
                        <i className="bi-record-circle-fill"></i>
                        {parse(item.content)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ):<MiniLoading />}
            <div className="action">
              <button
                className="btn btn-white btn-prev"
                onClick={() => {
                  let index = -1;
                  for (let i = 0; i < questions_data.length; i++) {
                    const el = questions_data[i];
                    if (el.id === item.question.id) {
                      if (questions_data[i - 1] !== undefined) {
                        index = i - 1;
                      }
                    }
                  }
                  if (index !== -1) {
                    fetch_question(questions_data[index].id);
                  }
                }}
              >
                {i18next.t("Prev")}
              </button>
              <button
                className="btn btn-white btn-next"
                onClick={() => {
                  let index = -1;
                  for (let i = 0; i < questions_data.length; i++) {
                    const el = questions_data[i];
                    if (el.id === item.question.id) {
                      if (questions_data[i + 1] !== undefined) {
                        index = i + 1;
                      }
                    }
                  }

                  if (index !== -1) {
                    fetch_question(questions_data[index].id);
                  }
                }}
              >
                {i18next.t("Next")}
              </button>
              <button
                className="btn btn-white btn-finish"
                onClick={() => {
                  swal({
                    title: i18next.t("warning?"),
                    text: i18next.t("finishe_quiz?"),
                    icon: "warning",
                    buttons: {
                      cancel: i18next.t("cancel"),
                      ok: {
                        text: i18next.t("ok"),
                        value: true,
                      },
                    },
                    dangerMode: true,
                  }).then((result) => {
                    if (result) {
                      finish();
                    }
                  });
                  // finish_quiz();
                }}
              >
                {i18next.t("finish quiz")}
              </button>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div style={{ width: "100%" }}>
              <h4>{i18next.t("question number")}</h4>
              <div className="page_num">
                {questions_data != undefined &&
                  questions_data.map((_item, index) => (
                    <span
                      key={index}
                      className={
                        (item!=null &&
                        _item.id == item.question.id) ? "op75 item active" : ("op75 item " + (_item.total > 0 ? "checked" : ""))
                      }
                      onClick={() => fetch_question(_item.id)}
                    >
                      {index + 1}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LVLQuiz;

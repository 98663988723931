import i18next from "i18next";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
import "swiper/swiper.min.css";
function Slider() {
  const data = [
    {
      image: i18next.t("slide_image_1"),
      title: i18next.t("slide_title_1"),
      descr: i18next.t("slide_descr_1"),
      link: i18next.t("slide_link_1"),
    },
    {
      image: i18next.t("slide_image_2"),
      title: i18next.t("slide_title_2"),
      descr: i18next.t("slide_descr_2"),
      link: i18next.t("slide_link_2"),
    },
    {
      image: i18next.t("slide_image_3"),
      title: i18next.t("slide_title_3"),
      descr: i18next.t("slide_descr_3"),
      link: i18next.t("slide_link_3"),
    },
  ];
  return (
    <div id="home-swiper-slider">
      <Swiper spaceBetween={50} slidesPerView={1}>
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <div className='className="m-5 p-5'>
                    <h3>{item.title}</h3>
                    <p>{item.descr}</p>
                    <Link className="btn btn-white" to={item.link}>
                      {i18next.t("read_more")}
                    </Link>
                  </div>
                </div>
                <div
                  className="col-xs-12 col-md-7 slide"
                  style={{
                    backgroundImage: "url(/images/slider1.jpeg)",
                  }}
                ></div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
export default Slider;

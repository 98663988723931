import { useEffect, useState } from "react";
import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CountDown from "../../components/CountDown";
import parse from "html-react-parser";
import swal from "sweetalert";
import i18next from "i18next";
import {
  get_bnl_checked_questions,
  post_bnl_question,
  post_finish_quiz,
  post_finish_quiz_tur1,
  user_quiz_tur,
  user_quiz_tur_lesson_question,
  user_quiz_tur_lesson_questions,
} from "../../services/bnlApi";
import { Badge, Button, Card } from "react-bootstrap";

const NZMQuiz = (props) => {
  const history = useHistory();
  const { token, lesson, tur } = useParams();

  const [lessons, setLessons] = useState([]);

  const [question, setQuestion] = useState({});
  const [questions, setQuestions] = useState([]);
  const finish_quiz = () => {
    post_finish_quiz(token).then((res) => {
      if (res.status === "success") {
        history.push("/user/exam/nzm");
      }
    });
  };
  const tur1IsFinished = () => {
    if (lessons.data) {
      var f = lessons.data.filter((el) => el.tur == 1 && el.is_finished == 1);

      return f.length == 0 ? false : true;
    }
    return false;

  };
  const finishTur1 = () => {
    
    var f = lessons.data.filter((el) => el.tur == 1);
    console.log(f, lessons);
    post_finish_quiz_tur1(f[0].token).then((res) => {
      window.location.reload();
    });
  };
  const submit_answer = (item) => {

    let c = question.answers.reduce((total, i) => {
      return total + (i.is_checked ? 1 : 0);
    }, 0);
    let max_choice = question.question.max_choice;
    if (max_choice > c) {
      post_bnl_question("nzm", item.id, !item.is_checked).then((res) => {
        if (res.status === "updated") {
          fetch_user_quiz_tur_lesson_question(question.question.id);
          fetch_checked_questions();
        }
      });
    } else {
      if (item.is_checked) {
        post_bnl_question("nzm", item.id, item.is_checked ? 0 : 1).then(
          (res) => {
            if (res.status === "updated") {
              fetch_user_quiz_tur_lesson_question(question.question.id);
              fetch_checked_questions();
            }
          }
        );
      } else {
        swal(i18next.t("Info"), i18next.t("max_choice"), "info");
      }
    }
  };

  const fetch_checked_questions = async () => {

    await get_bnl_checked_questions('nzm', tur, lesson).then((res) => setQuestions(res));
  }

  const fetch_lessons = async () => {
    
    await user_quiz_tur("nzm", token).then((res) => {
      setLessons(res);
      if (lesson == undefined) {

        history.push({
          pathname: "/bnl/quiz/nzm/" + token + "/" + res.data[0].bnl_quiz_lesson_id + "/" + res.data[0].id,
        });
        // fetch_user_quiz_tur_lesson_questions();
      } 
      // setTimeout(function () {
      //   console.log(lessons);
      //   fetch_user_quiz_tur_lesson_questions();
      // }, 2000);
    });
  };

  const fetch_user_quiz_tur_lesson_questions = async (tur_id, lesson_id) => {
    await user_quiz_tur_lesson_questions('nzm', tur_id, lesson_id).then((res) => {
      setQuestions(res);

      fetch_user_quiz_tur_lesson_question(res[0].id);
    });
    // if (res.length > 0) {
    //   let _lesson = res.filter((el) => el.bnlQuizLessonId == lesson);
    //   if (_lesson.length > 0) {
    //     if (_lesson[0].questions.length > 0) {
    //       setQuestions(_lesson[0].questions);
    //       fetch_user_quiz_tur_lesson_question(_lesson[0].questions[0].id);
    //     }
    //   }
    // }

    // await user_quiz_tur_lesson_questions("nzm", token, lesson).then((res) => {
    //   setQuestions(res);

    //   fetch_user_quiz_tur_lesson_question(res[0].id);
    // });
  };
  const fetch_user_quiz_tur_lesson_question = async (id) => {
    await user_quiz_tur_lesson_question("nzm", id).then((res) => {
      setQuestion(res);
      // fetch_lessons();
    });
  };

  useEffect(() => {

    if (lesson != null) {
      fetch_user_quiz_tur_lesson_questions(tur, lesson);
    }
    fetch_lessons();

  }, [lesson]);

  return (
    <div className="bg-gray mt-4">
      <div className="container" id="quiz">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-8">
            <h4 className="name-time">
              {i18next.t("General Lesson")}
              {
                lessons.seconds!=undefined ?  <CountDown
                seconds={parseInt(lessons.seconds) > 0 ? parseInt(lessons.seconds):10}
                time_expired={() => finish_quiz()}
              />:"loading..."
              }
            </h4>

            <div className="question">
              <h4>
                {i18next.t("Question:")} {question.id}
              </h4>
              <hr />
              <div className="q">
                {question.question && parse(question.question.content)}
              </div>
              <div className="answer">
                <ul>
                  {question.answers &&
                    question.answers.map((item, index) => (
                      <li
                        key={item.id}
                        className={item.is_checked ? "check" : ""}
                        onClick={() => {
                          submit_answer(item);
                        }}
                      >
                        <i className="bi-record-circle-fill"></i>
                        {parse(item.content)}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="action">
                <button
                  className="btn btn-white btn-prev"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < questions.length; i++) {
                      const el = questions[i];
                      if (el.id === question.question.id) {
                        if (questions[i - 1] !== undefined) {
                          index = i - 1;
                        }
                      }
                    }
                    if (index !== -1) {
                      fetch_user_quiz_tur_lesson_question(questions[index].id);
                    }
                  }}
                >
                  {i18next.t("Prev")}
                </button>
                <button
                  className="btn btn-white btn-next"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < questions.length; i++) {
                      const el = questions[i];
                      if (el.id === question.question.id) {
                        if (questions[i + 1] !== undefined) {
                          index = i + 1;
                        }
                      }
                    }

                    if (index !== -1) {
                      fetch_user_quiz_tur_lesson_question(questions[index].id);
                    }
                  }}
                >
                  {i18next.t("Next")}
                </button>
                <button
                  className="btn btn-white btn-finish"
                  onClick={() => {
                    swal({
                      title: i18next.t("warning?"),
                      text: i18next.t("finishe_quiz?"),
                      icon: "warning",
                      buttons: {
                        cancel: i18next.t("cancel"),
                        ok: {
                          text: i18next.t("ok"),
                          value: true,
                        },
                      },
                      dangerMode: true,
                    }).then((result) => {
                      if (result) {
                        finish_quiz();
                      }
                    });
                    // finish_quiz();
                  }}
                >
                  {i18next.t("finish")}
                </button>
              </div>
            </div>

            <div className="page_num">
              {questions.map((item, index) => {
                return (
                  <span
                    key={item.id}
                    className={
                      question.question && item.id === question.question.id
                        ? "op75 item active"
                        : "op75 item " + (item.total > 0 ? "checked" : "")
                    }
                    onClick={() => {
                      fetch_user_quiz_tur_lesson_question(item.id);
                    }}
                  >
                    {index + 1}
                  </span>
                );
              })}
            </div>
          </div>
          <div className=" col-xs-12 col-sm-12 col-md-4">
            <Card>
              <Card.Header>
                <h5 style={{ margin: 0 }}>
                  <span style={{ margin: 0, "lineeight": "26px" }}>
                    {i18next.t("tur1")}
                  </span>
                  {tur1IsFinished() == false ? (
                    <Button
                      size="sm"
                      variant="success"
                      className="float-end"
                      onClick={() => finishTur1()}
                    >
                      {i18next.t("finish tur1")}
                    </Button>
                  ) : (
                    <Badge
                      variant="primary"
                      className="float-end"
                      style={{ background: "green" }}
                    >
                      {i18next.t("tur 1 is finished")}
                    </Badge>
                  )}
                </h5>
              </Card.Header>
              <Card.Body>
                <ul>
                  {lessons.data && lessons.data.map((item, index) =>
                    item.tur === 1 ? (
                      <li key={item.name}>
                        <Link
                          className={
                            parseInt(lesson) == parseInt(item.bnl_quiz_lesson_id)
                              ? "active-lesson"
                              : ""
                          }
                          to={
                            "/bnl/quiz/nzm/" +
                            token +
                            "/" +
                            item.bnl_quiz_lesson_id +
                            "/" +
                            item.id
                          }
                        >
                          {item.name}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </Card.Body>
              <Card.Header>
                <h5>{i18next.t("tur2")}</h5>
              </Card.Header>
              <Card.Body>
                <ul>
                  {lessons.data && lessons.data.map((item, index) =>
                    item.tur === 2 ? (
                      <li key={item.name}>
                        <div style={{ textDecoration: 'underline', cursor: 'pointer' }}

                          className={
                            parseInt(lesson) == parseInt(item.bnlQuizLessonId)
                              ? "active-lesson"
                              : ""
                          }
                          onClick={() => {
                            if (tur1IsFinished() == false) {
                              swal(i18next.t("tur 1 not finished"));
                            } else {
                              history.push(
                                "/bnl/quiz/nzm/" +
                                token +
                                "/" +
                                item.bnl_quiz_lesson_id +
                                "/" +
                                item.id
                              );
                            }
                          }}
                        >
                          {item.name}
                        </div>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NZMQuiz;

import axios from "axios";
const REACT_APP_API_URL = "https://qazbilim.online/api/";
// const REACT_APP_API_URL = "http://localhost:8000/";
const BASE_API = "https://qazbilim.online/api/";

const $host = axios.create({
  baseURL: REACT_APP_API_URL,
  verifySSL: false,
});

const $authHost = axios.create({
  baseURL: REACT_APP_API_URL,
  verifySSL: false,
});

const authInterceptor = (config) => {
  config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
  // config.headers.responseType = "blob";
  return config;
};

$authHost.interceptors.request.use(authInterceptor);
$authHost.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if (error.response && error.response.status === 401) {
    //   window.location.href = "/login";
    // }
    return Promise.reject(error);
  }
);

export { $host, $authHost, BASE_API, REACT_APP_API_URL };

import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router";
import { lwa_exam } from "../../../services/userApi";

import Account from "../Account";
import parse from "html-react-parser";

const LwaDetail = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { lwa, token } = useParams();
  useEffect(() => {
    setLoading(true);
    lwa_exam(lwa, token).then((res) => {
      setData(res);
      setLoading(false);
    });
  }, []);

  return (
    <Fragment>
      <div className="main-title">{i18next.t(lwa)}</div>
      <div id="LwaDetail" className="container">
        <h3 className="title">{i18next.t(lwa + "_result")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="exams" />
          </div>
          <div className="col-xs-12 col-md-8" id="quiz">
            {data.map((v, index) => {
              return (
                <div className="question mb-2" key={index}>
                  <div className="q">
                    {index + 1}. {parse(v.content)}
                  </div>
                  <div className="answer">
                    <ul>
                      {v.answer.map((a, ii) => (
                        <li
                          className={
                            a.is_checked == 1 && a.is_true == 1
                              ? "correct"
                              : a.is_checked == 1
                              ? "wrong"
                              : ""
                          }
                        >
                          <i className="bi-record-circle-fill"></i>
                          {a.content && parse(a.content)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LwaDetail;

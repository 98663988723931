import React, { useEffect, useState } from "react";

const CountDown = (props) => {
  const [timer, setTimer] = useState(null);
  const calculateTimeLeft = (s) => {
    let timeLeft = { hours: "00", minutes: "00", seconds: "00" };

    if (s > 0) {
      let h = Math.floor((s / (60 * 60)) % 24);
      let m = Math.floor((s / 60) % 60);
      let ss = Math.floor(s % 60);
      timeLeft = {
        hours: h > 9 ? h : "0" + h,
        minutes: m > 9 ? m : "0" + m,
        seconds: ss > 9 ? ss : "0" + ss,
      };
    } else {
      return null;
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    let ss = parseInt(props.seconds);
    if (ss && timer === null) {
      let t_timer = setInterval(() => {
        const t = calculateTimeLeft(ss);
        if (t === null) {
          clearInterval(timer);
          props.time_expired();
        } else {
          setTimeLeft(t);
          ss = ss - 1;
        }
      }, 1000);
      setTimer(t_timer);
    }
  }, [props]);

  return (
    <span>
      {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
    </span>
  );
};

export default CountDown;

import i18next from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get_categories } from "../../services/courseApi";
import MiniLoading from "../MiniLoading";
const CourselistFilter = (props) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const sort_by = [
    {
      val: "new",
      text: i18next.t("New Courses"),
    },
    {
      val: "discount",
      text: i18next.t("Discounted Courses"),
    },
    {
      val: "featured",
      text: i18next.t("Featured"),
    },
  ];

  const fetch_video_categories = async () => {
    setLoading(true);
    await get_categories().then((res) => setCategories(res), setLoading(false));
  };
  useEffect(() => {
    if(categories.length===0){
      fetch_video_categories();
    }
    
  }, [props, categories]);
  return (
    <Fragment>
      <h3 className="filter-title">{i18next.t("Sort By")}</h3>
      <ul className="filter-list">
        {sort_by.map((c, index) => (
          <li key={index}>
            <Link to={"/course/" + c.val}>
              {c.val === props.sort ? (
                <b>
                  <i className="bi-circle-fill"></i> {c.text}
                </b>
              ) : (
                <span>
                  <i className="bi-circle"></i> {c.text}
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>

      <h3 className="filter-title">{i18next.t("Categories")}</h3>
      <ul className="filter-list">
        <li>
          <Link to={"/course/" + props.sort}>
            {props.category ? (
              <span>
                <i className="bi-circle"></i> {i18next.t("All Category")}
              </span>
            ) : (
              <b>
                <i className="bi-circle-fill"></i> {i18next.t("All Category")}
              </b>
            )}
          </Link>
        </li>
        {loading ? (
          <MiniLoading />
        ) : (
          categories.map((c, index) => (
            <li key={index}>
              <Link to={"/course/" + props.sort + "/" + c.id}>
                {c.id === parseInt(props.category) ? (
                  <b>
                    <i className="bi-circle-fill"></i> {c.name}
                  </b>
                ) : (
                  <span>
                    <i className="bi-circle"></i> {c.name}
                  </span>
                )}
              </Link>
            </li>
          ))
        )}
      </ul>
    </Fragment>
  );
};
export default CourselistFilter;

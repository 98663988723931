import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";

import { get_user_info } from "../../services/userApi";

const Account = (props) => {
  const [data, setData] = useState({});
  const get_info = async () => {
    await get_user_info().then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    get_info();
  }, []);
  return (
    <Fragment>
      <div id="account">
        <div className="account-background d-flex justify-content-center">
          <img
            src={
              "https://ui-avatars.com/api/?name=" +
              data.first_name +
              "+" +
              data.last_name
            }
            alt={data.first_name + "+" + data.last_name}
            className="account-img"
          />
        </div>
        <div className="text-center mt-5">
          <h4 className="">
            {data.first_name} {data.last_name}{" "}
            <Link to="/user/profile" className="edit p-3">
              <i className="bi bi-pencil-square"></i>
            </Link>
          </h4>
          <p className="mail">{data.email}</p>
          <h4 className={props.active === "charge" ? "active" : ""}>
            {i18next.t("balance")}:{data.amount}
          </h4>
        </div>

        <div className="p-3">
          <ul id="account-links">
            <li className={props.active === "books" ? "active" : ""}>
              <Link to="/user/charge">
                <div className="icon">
                  <i className="bi bi-wallet" />
                </div>
                {i18next.t("charge_wallet")}
              </Link>
            </li>
            <li className={props.active === "books" ? "active" : ""}>
              <Link to="/user/books">
                <div className="icon">
                  <i className="bi bi-book" />
                </div>
                {i18next.t("my_books")}
              </Link>
            </li>
            <li className={props.active === "courses" ? "active" : ""}>
              <Link to="/user/courses">
                <div className="icon">
                  <i className="bi bi-person-video" />
                </div>
                {i18next.t("my_courses")}
              </Link>
            </li>
            {/* <li className={props.active === "results" ? "active" : ""}>
              <Link to="/user/results">{i18next.t("my_results")}</Link>
            </li> */}
            <li className={props.active === "exams" ? "active" : ""}>
              <Link to="/user/exams">
                <div className="icon">
                  <i className="bi bi-card-checklist" />
                </div>
                {i18next.t("my_exams")}
              </Link>
            </li>
            <li className={props.active === "history" ? "active" : ""}>
              <Link to="/user/history">
                <div className="icon">
                  <i className="bi bi-list-check" />
                </div>
                {i18next.t("payment_history")}
              </Link>
            </li>
            <li className={props.active === "change-password" ? "active" : ""}>
              <Link to="/user/change-password">
                <div className="icon">
                  <i className="bi bi-shield-lock" />
                </div>
                {i18next.t("change-password")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Account;

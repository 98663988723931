import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { Context } from "..";
import i18next from "i18next";
import { privateRoutes, publicRoutes } from "../routes";
import { Alert } from "react-bootstrap";
const AppRouter = observer(() => {
  const { user } = useContext(Context);

  const isAuth = user._isAuth;
  console.log(isAuth);
  return (
    <Switch>
      {isAuth &&
        privateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} component={Component} exact />
        ))}

      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} component={Component} exact />
      ))}
      <Route path="*">
        <div className="container">
          <div className="justify-content-center mt-5">
            <Alert variant="info">{i18next.t("route not found")}</Alert>
          </div>
        </div>
      </Route>
    </Switch>
  );
});

export default AppRouter;

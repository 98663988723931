import i18next from "i18next";
import { Fragment, useContext, useEffect, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import RelatedBooks from "../../components/book/RelatedBooks";
import MiniLoading from "../../components/MiniLoading";
import { Alert, Badge, Card, Form, ListGroup, Modal } from "react-bootstrap";
import swal from "@sweetalert/with-react";

import {
  book_buy_book,
  book_delivery,
  book_pages,
  get_book,
  get_book_content,
} from "../../services/bookApi";

import { REACT_APP_API_URL } from "../../services";
import PDFViewer from "mgr-pdf-viewer-react";
import { add_to_cart } from "../../services/userApi";
import { user_quiz } from "../../services/lwaApi";
import { observer } from "mobx-react-lite";
import { Context } from "../..";

const BookDetail = observer((props) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);

  const [order_type, setOrderType] = useState(0);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("#");

  const [last_name, setLastName] = useState("");
  const [first_name, setFirstName] = useState("");
  const [delibery_address, setDeliberyAddress] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [qty, setQty] = useState(1);

  const { user } = useContext(Context);

  const handleClose = () => setShow(false);
  //  const handleShow = () => setShow(true);

  let { id } = useParams();
  const get_book_page = async () => {
    await get_book_content(id, pageNumber).then((res) => {
      setUrl(res);
      setShow(true);
    });
  };
  const read_book = async () => {
    setTitle(item.title);
    setShow(true);
    await book_pages(id).then((res) => {
      if (res.pages > 0) {
        setNumPages(res.pages);
        setPageNumber(1);
        get_book_page();
      } else {
        swal(i18next.t("book_not_found"), "", "info");
      }
    });
  };
  const buy_book = async () => {
    var error = false;

    if (order_type == 0) {
      swal("select order type");
    } else if (order_type == 1 && item.is_pdf != 1) {
      swal("book is not pdf");
    } else if (order_type == 2 && item.is_book != 1) {
      swal("book is not delivery book");
    } else {
      if (order_type == 2) {
        if (first_name == "") {
          swal(i18next.t("error"), i18next.t("first name required"), "error");
          error = true;
        } else if (last_name == "") {
          swal(i18next.t("error"), i18next.t("last name required"), "error");
          error = true;
        } else if (delibery_address == "") {
          swal(
            i18next.t("error"),
            i18next.t("delibery_address required"),
            "error"
          );
          error = true;
        } else if (phone_number == "") {
          swal(i18next.t("error"), i18next.t("phone_number required"), "error");
          error = true;
        }
      }
      if (error == false) {
        setLoading(true);
        await book_buy_book(id, order_type, {
          first_name,
          last_name,
          delibery_address,
          phone_number,
          qty,
        })
          .then((res) => {
            if (res.status === "success") {
              swal(i18next.t("success"), i18next.t("success_text"), "success");
              fetch_one();
            } else {
              swal(i18next.t("error"), res.message, "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              swal(i18next.t("error"), i18next.t("first login"), "error");

              setLoading(false);
            }
          });
      }
    }
  };
  useEffect(() => {
    console.log(props.match.params);
    if (props.match && props.match.params) {
      fetch_one();
    }
  }, [props]);

  const fetch_one = async () => {
    setLoading(true);
    await get_book(id)
      .then((res) => {
        window.scrollTo(0, 0);
        setItem(res);
        setLoading(false);
      })
      .catch((err) => alert(err));
  };
  const addToCart = () => {
    var error = false;
    if (order_type == 0) {
      swal("select order type");
    } else if (order_type == 1 && item.is_pdf != 1) {
      swal("book is not pdf");
    } else if (order_type == 2 && item.is_book != 1) {
      swal("book is not delivery book");
    } else {
      if (order_type == 2) {
        if (first_name == "") {
          swal(i18next.t("error"), i18next.t("first name required"), "error");
          error = true;
        } else if (last_name == "") {
          swal(i18next.t("error"), i18next.t("last name required"), "error");
          error = true;
        } else if (delibery_address == "") {
          swal(
            i18next.t("error"),
            i18next.t("delibery_address required"),
            "error"
          );
          error = true;
        } else if (phone_number == "") {
          swal(i18next.t("error"), i18next.t("phone_number required"), "error");
          error = true;
        }

        if (qty < 1) {
          swal(i18next.t("error"), i18next.t("min qty 1 "), "error");
          error = true;
        }
      }

      if (error == false) {
        add_to_cart("book", id, {
          qty: qty,
          delivery_address: {
            first_name,
            last_name,
            delibery_address,
            phone_number,
          },
        })
          .then((res) => {
            if (res.status == "success") {
              user.setCartCount(res.count);
              swal(i18next.t("successfuly added to cart"));
              setOrderType(1);
            }
            if (res.status == "error") {
              // user.setCartCount(res.count);
              swal(i18next.t(res.message));
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              swal(i18next.t("error"), i18next.t("first login"), "error");

              setLoading(false);
            }
          });
      }
    }
  };

  //const max_rate = 5;
  const curr_rate = 3.5; //1.3
  const fill_rate = curr_rate | 0; //1
  const half_rate = curr_rate - fill_rate; //1.3-1 = 0.3
  const empty_rate = 5 - fill_rate - (half_rate > 0 ? 1 : 0); //5-1-1=3 (f h e e e

  return (
    <Fragment>
      <div id="book">
        <div className="container">
          <div className="row">
            {loading ? (
              <MiniLoading />
            ) : (
              <Fragment>
                <div className="col-xs-12 col-md-8">
                  {item.id ? (
                    <PDFViewer
                      document={{
                        url:
                          REACT_APP_API_URL +
                          "download-book/" +
                          item.id +
                          "/short",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <PDFViewer
                        document={{
                          url: url,
                        }}
                        hideNavbar={true}
                      />

                      <p className="text-center">
                        {pageNumber > 1 ? (
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              setPageNumber(pageNumber - 1);
                              get_book_page();
                            }}
                          >
                            {i18next.t("prev")}
                          </button>
                        ) : (
                          ""
                        )}
                        {i18next.t("page")} {pageNumber} {i18next.t("of")}{" "}
                        {numPages}
                        {pageNumber < numPages ? (
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              setPageNumber(pageNumber + 1);
                              get_book_page();
                            }}
                          >
                            {i18next.t("next")}
                          </button>
                        ) : (
                          ""
                        )}
                      </p>
                    </Modal.Body>
                  </Modal>
                </div>
                <div className="col-xs-12 col-md-4">
                  <Card key="Light">
                    <Card.Body>
                      <Card.Title>{item.title}</Card.Title>
                      <Card.Subtitle>{item.author}</Card.Subtitle>

                      <div className="row mt-3">
                        {item.is_paid ? (
                          ""
                        ) : (
                          <Fragment>
                            <p className="col price">{item.price}₸</p>
                            {props.hide_star !== true ? (
                              <div className="rate col">
                                {[...Array(fill_rate)].map((x, index) => (
                                  <i key={index} className="bi-star-fill"></i>
                                ))}
                                {[...Array(half_rate > 0 ? 1 : 0)].map(
                                  (x, index) => (
                                    <i key={index} className="bi-star-half"></i>
                                  )
                                )}
                                {[...Array(empty_rate)].map((x, index) => (
                                  <i key={index} className="bi-star"></i>
                                ))}
                              </div>
                            ) : (
                              ""
                            )}
                            <div>
                              <label>{i18next.t("order type")}</label>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  if (e.target.value == 0) {
                                    swal("select order type");
                                  } else if (
                                    e.target.value == 1 &&
                                    item.is_pdf != 1
                                  ) {
                                    swal("book is not pdf");
                                  } else if (
                                    e.target.value == 2 &&
                                    item.is_book != 1
                                  ) {
                                    swal("book is not delivery book");
                                  }
                                  setOrderType(e.target.value);
                                }}
                              >
                                <option value="0">
                                  {i18next.t("Select Type")}
                                </option>
                                <option value="1">
                                  {i18next.t("online_book")}
                                </option>
                                <option value="2">
                                  {i18next.t("delivery_book")}
                                </option>
                              </select>
                            </div>
                            {order_type == 2 ? (
                              <Fragment>
                                <Form.Group className="mb-3 mt-3">
                                  <div className="d-flex justify-content-between align-items-start">
                                    <span
                                      style={{
                                        padding: "10px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {i18next.t("Deliver Price")}:
                                    </span>

                                    <span
                                      className="badge badge-primary"
                                      style={{
                                        backgroundColor: "#007bff",
                                        padding: "10px",
                                      }}
                                    >
                                      +2000
                                    </span>
                                  </div>
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="last_name"
                                >
                                  <Form.Label>
                                    {i18next.t("last_name")}
                                  </Form.Label>
                                  <Form.Control
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                    type="text"
                                    placeholder={i18next.t("last_name")}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="first_name"
                                >
                                  <Form.Label>
                                    {i18next.t("first_name")}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    placeholder={i18next.t("first_name")}
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3"
                                  controlId="phone_number"
                                >
                                  <Form.Label>
                                    {i18next.t("phone_number")}
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    onChange={(e) =>
                                      setPhoneNumber(e.target.value)
                                    }
                                    placeholder={i18next.t("phone_number")}
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="delibery_address"
                                >
                                  <Form.Label>
                                    {i18next.t("delibery_address")}
                                  </Form.Label>
                                  <Form.Control
                                    onChange={(e) =>
                                      setDeliberyAddress(e.target.value)
                                    }
                                    as="textarea"
                                    rows={3}
                                    placeholder={i18next.t("delibery_address")}
                                  />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="qty">
                                  <Form.Label>{i18next.t("qty")}</Form.Label>
                                  <Form.Control
                                    onChange={(e) => setQty(e.target.value)}
                                    type="number"
                                    min="1"
                                    value={qty}
                                    placeholder={i18next.t("qty")}
                                  />
                                </Form.Group>
                              </Fragment>
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </Fragment>
                        )}
                      </div>
                      {item.is_paid ? (
                        item.is_delivery ? (
                          <Fragment>
                            <Alert
                              variant={
                                item.status == "ordered"
                                  ? "info"
                                  : item.status == "rejected"
                                  ? "warning"
                                  : item.status == "delivered"
                                  ? "success"
                                  : item.status == "on_way"
                                  ? "primary"
                                  : item.status == "order_accepted"
                                  ? "secondary"
                                  : ""
                              }
                            >
                              {i18next.t("status")}: {i18next.t(item.status)}
                            </Alert>
                            {item.status == "rejected" ? (
                              <Fragment>
                                <button
                                  className="btn btn-success button-success w-100"
                                  onClick={() => {
                                    setOrderType("1");
                                    buy_book();
                                  }}
                                >
                                  {i18next.t("buy_book")}
                                </button>
                                <button
                                  className="btn btn-primary button-primary w-100"
                                  onClick={addToCart}
                                >
                                  {i18next.t("add to cart")}
                                </button>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <h4>{i18next.t("delivery_address")}</h4>
                                {item.address != "" &&
                                item.address != undefined &&
                                item.address != null ? (
                                  <ul>
                                    <li>
                                      {i18next.t("ordered qty")}: {item.qty}
                                    </li>
                                    <li>
                                      {i18next.t("receiver_name")}:
                                      {item.address.first_name}
                                      {item.address.last_name}
                                    </li>
                                    <li>
                                      {i18next.t("receiver_phone")}:
                                      {item.address.phone}
                                    </li>
                                    <li>
                                      {i18next.t("receiver_email")}:
                                      {item.address.postal_address}
                                    </li>
                                    <li>
                                      {i18next.t("receiver_address")}:
                                      {item.address.delibery_address}
                                    </li>
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </Fragment>
                            )}
                          </Fragment>
                        ) : (
                          <button
                            className="btn btn-success button-success w-100"
                            onClick={read_book}
                          >
                            {i18next.t("read_book")}
                          </button>
                        )
                      ) : (
                        <Fragment>
                          <button
                            className="btn btn-success button-success w-100"
                            onClick={buy_book}
                          >
                            {i18next.t("buy_book")}
                          </button>

                          <button
                            className="btn btn-primary button-primary w-100"
                            onClick={addToCart}
                          >
                            {i18next.t("add to cart")}
                          </button>
                        </Fragment>
                      )}

                      <p className="paragraph mt-2">{item.description}</p>
                    </Card.Body>
                  </Card>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <div id="books">
        <RelatedBooks />
      </div>
    </Fragment>
  );
});

export default BookDetail;

import { useEffect, useState } from "react";
import { page_data } from "../services/publicApi";
import parse from "html-react-parser";
import React    from 'react';

const About = () => {
  const [page_content, setPageContent] = useState({});
  const fetch_page = (key) => {
    page_data(key).then((res) => {
      setPageContent(res);
    });
  };
  useEffect(() => {
    fetch_page("about");
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <h3>{page_content.title}</h3>
          <div>{page_content.content && parse(page_content.content)}</div>
        </div>
      </div>
    </div>
  );
};

export default About;

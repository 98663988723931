import i18next from "i18next";
import { Fragment, useContext, useEffect, useState } from "react";
import React from "react";
import UbtMainLesson from "../../components/ubt/UbtMainLesson";
import { get_ubt_lessons } from "../../services/publicApi";

import swal from "@sweetalert/with-react";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import { buy_quiz_get_token } from "../../services/ubtApi";

const Ubt = observer(() => {
  const { user } = useContext(Context);
  const [items, setItems] = useState([]);

  const [firstLesson, setFirstLesson] = useState(null);
  const [secondLesson, setSecondLesson] = useState(null);
  const [firstLessonRelation, setFirstLessonRelation] = useState([]);

  const selectItem = (item) => {
    if (firstLesson) {
      if (firstLesson.id === item.id) {
        setFirstLesson(null);
        setSecondLesson(null);
        setFirstLessonRelation([]);
      } else if (firstLesson && secondLesson) {
        if (secondLesson.id === item.id) {
          setSecondLesson(null);
        } else {
          setSecondLesson(item);
        }
      } else if (firstLesson) {
        setSecondLesson(item);
      }
    } else {
      setFirstLesson(item);
      let arr = item.relation.split(",");
      setFirstLessonRelation(arr);
      setSecondLesson(null);
    }
  };

  useEffect(() => {
    fetch_data();
  }, []);

  const fetch_data = async () => {
    await get_ubt_lessons().then(async (res) => {
      await setItems(res);
    });
  };

  const is_disabled = (item) => {
    if (firstLesson && item.id === firstLesson.id) {
      return false;
    }
    if (secondLesson && secondLesson.id === item.id) {
      return false;
    }
    if (firstLessonRelation.includes("" + item.id)) {
      return false;
    }
    if (firstLesson) {
      return true;
    }
  };
  const get_class = (item) => {
    if (firstLesson && firstLesson.id === item.id) {
      return "mb-4 item selected";
    }
    if (secondLesson && secondLesson.id === item.id) {
      return "mb-4 item selected";
    }
    if (firstLesson && !firstLessonRelation.includes("" + item.id)) {
      return "mb-4 item";
    }
    return "mb-4 item";
  };

  const buy_quiz = () => {
    if (firstLesson === null) {
      swal(
        i18next.t("select lessons"),
        {
          buttons: {
            ok: i18next.t("Ok"),
          },
        },
        "warning"
      );
    }
    if (firstLesson && secondLesson === null) {
      swal(
        i18next.t("select second lesson"),
        {
          buttons: {
            ok: i18next.t("Ok"),
          },
        },
        "warning"
      );
    }

    if (!user.IsAuth()) {
      swal(
        i18next.t("first login"),
        {
          buttons: {
            ok: i18next.t("Ok"),
          },
        },
        "warning"
      );
    }
    if (user.IsAuth() && firstLesson && secondLesson) {
      buy_quiz_get_token({
        first: firstLesson.id,
        second: secondLesson.id,
      }).then((res) => {
        if (res.status === "error") {
          swal(i18next.t("error"), res.message, "error");
        }
        if (res.status === "success") {
          window.location.href = "/#/ubt/quiz/" + res.token;
        }
      });
    }
  };

  return (
    <div id="ubt" className="bg-gray pb-5">
      <div className="main-title">{i18next.t("ubt_title")}</div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h3 className="title">{i18next.t("buy_ubt_quiz")}</h3>
          </div>
        </div>
        <UbtMainLesson
          data={items.filter((el) => el.lesson_type === "primary")}
        />
        <div className="mt-3 text-center">
          <div className="row">
            {items
              .filter((el) => el.lesson_type === "secondary")
              .map((item, index) => (
                <div
                  className={
                    is_disabled(item) == true
                      ? "col-xs-12 col-md-3 d-none"
                      : " col-xs-12 col-md-3 "
                  }
                  key={index}
                >
                  <div className={get_class(item)}>
                    <i className="bi-pencil"></i>
                    <h3>{item.name}</h3>
                    <p>{item.text}</p>
                    <button
                      disabled={is_disabled(item)}
                      onClick={() => selectItem(item)}
                    >
                      {i18next.t("select")}
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="col-xs-12">
            <div className="text-center">
              <button className="btn btn-blue" onClick={() => buy_quiz()}>
                {i18next.t("buy_quiz")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default Ubt;

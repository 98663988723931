import { useEffect, useState } from "react";
import React from "react";
import i18next from "i18next";
import { Badge, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";

import MiniLoadingResult from "../../../components/MiniLoadingResult";
import { my_ubt_exam_item_caluelate } from "../../../services/userApi";

const UbtExamItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoading(true);
    my_ubt_exam_item_caluelate(props.item.id).then((res) => {
      setLoading(false);
      setData(res);
    });
  }, [props]);
  return (
    <div className="col-xs-12 col-md-6">
      <Card className="mb-3">
        <Card.Header>
          {/* <Link to={"/user/exam/ubt/result/" + props.item.token}> */}
          {props.item.token}
          <span style={{float:'right'}}>{props.item.created_at}</span>
          {/* </Link> */}
        </Card.Header>
        {loading ? (
          <MiniLoadingResult />
        ) : (
          data.map((v, i) => {
            return (
              <ListGroup className="list-group-flush" key={i}>
                <ListGroupItem>
                  <Link
                    to={
                      "/user/exam/ubt/result/" + props.item.token + "/" + v.id
                    }
                  >
                    {v.lesson}
                  </Link>
                  <Badge variant="primary" className="float-right bg-gray1">
                    {v.max_point} / {v.total_point}
                  </Badge>
                </ListGroupItem>
              </ListGroup>
            );
          })
        )}
        <Card.Footer style={{ textAlign: "right" }}>
          <b>
            {i18next.t("total_result")}:{" "}
            {data.length > 0
              ? data.reduce((a, v) => a + parseInt(v.max_point), 0)
              : 0}
            /{" "}
            {data.length > 0
              ? data.reduce((a, v) => a + parseInt(v.total_point), 0)
              : 0}
          </b>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default UbtExamItem;

import { useEffect, useState } from "react";
import React    from 'react';
import { news } from "../../services/publicApi";
import parse from "html-react-parser";
import LatestNews from "../../components/LatestNews";
import {REACT_APP_API_URL} from "../../services/";

const NewsDetail = (props) => {
  const [item, setItem] = useState({});
  const fetch_news = async (id) => {
    await news(id).then((res) => setItem(res));
  };
  useEffect(() => {
    if (props.match && props.match.params) {
      fetch_news(props.match.params.id);
    }
  }, [props]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-8">
          {item.
          cover_path ? (
            <div
              className="news-cover"
              style={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: 300 + "px",
                  backgroundImage: `url(${REACT_APP_API_URL}${item.cover_path})`,
              }}
            ></div>
          ) : (
            ""
          )}
          <h3 className="pb-1 pt-1">{item.title}</h3>
          <p>{item.content && parse(item.content)}</p>
        </div>
        <div className="col-xs-12 col-md-4">
          <LatestNews />
        </div>
      </div>
    </div>
  );
};
export default NewsDetail;

import i18next from "i18next";
import React  from 'react';

const MiniLoadingResult = () => {
  return (
    <div className="mini-loading">
      <div className="indicator">
        <div className="loader"></div>
        <h6>{i18next.t("loading result...")}</h6>
      </div>
    </div>
  );
};

export default MiniLoadingResult;

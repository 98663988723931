import { makeAutoObservable } from "mobx";
export default class Store {
  constructor() {
    this._isAuth = false;
    this._user = {};
    this.cart_count = 0;
    this._isLoading = false;
    makeAutoObservable(this);
  }

  setIsAuth(_bool) {
    this._isAuth = _bool;
  }
  setUser(user) {
    this._user = user;
  }

  setCartCount(cart_count) {
    this.cart_count = cart_count;
  }

  setIsLoading(_bool) {
    this._isLoading = _bool;
  }

  isLoading() {
    return this._isLoading;
  }
  getCartCount() {
    return this.cart_count;
  }

  IsAuth() {
    return this._isAuth;
  }
  getUser() {
    return this._user;
  }
}

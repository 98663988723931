import { Link } from "react-router-dom";
import {REACT_APP_API_URL} from "../../services/";
import React  from 'react';
function NewsSlider(props) {
  return (
    <div className="book-item">
      <Link to={"/news/" + props.item.id}>
          <img src={REACT_APP_API_URL + props.item.cover_path} alt={props.item.title} />
      </Link>
      <Link to={"/news/" + props.item.id}>
        <div className="name line">{props.item.title}</div>
      </Link>
    </div>
  );
}

export default NewsSlider;

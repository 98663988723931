import { $authHost } from "./index";

export const buy_quiz_get_token = async (form) => {
  const { data } = await $authHost.post("/ubt/buy-quiz", form);
  return data;
};

export const get_ubt_user_quiz = async (token) => {
  const { data } = await $authHost.get("/ubt/user-quiz/" + token);
  return data;
};

export const get_ubt_user_quiz_item_questions = async (token, uqi_id) => {
  const { data } = await $authHost.get(
    "/ubt/user-quiz-item-question/" + token + "/" + uqi_id
  );
  return data;
};

export const get_ubt_user_quiz_item_question = async (id) => {
  const { data } = await $authHost.get("/ubt/user-quiz/question/" + id);
  return data;
};

export const get_ubt_user_quiz_item_detail = async (id) => {
  const { data } = await $authHost.get("/ubt/ubt-exam-result/" + id);
  return data;
};

export const post_question_answer = async (id, is_checked) => {
  const { data } = await $authHost.post(
    "/ubt/user-quiz/question-answer/" + id + "/" + is_checked
  );
  return data;
};

export const post_finish_quiz = async (token) => {
  const { data } = await $authHost.post("/ubt/user-quiz/finish/" + token);
  return data;
};

export const post_question_answer_cross = async (id, is_checked, answer_id) => {
    const { data } = await $authHost.post(
      "/ubt/user-quiz/question-answer-cross/" + id + "/" + is_checked + "/" + answer_id
  );
  return data;
};


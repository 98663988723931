import { BrowserRouter } from "react-router-dom";

import { observer } from "mobx-react-lite";

import Header from "./components/header";
import Footer from "./components/footer";
import AppRouter from "./components/AppRouter";

import "katex/dist/katex.min.css";
import katex from "katex/dist/katex.mjs";

import HomeBookSlider from "./components/home/HomeBookSlider";
import HomeNewsSlider from "./components/home/HomeNewsSlider";
import SwiperCore, { Pagination } from "swiper/core";
import React, { useEffect, useState } from "react";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { REACT_APP_API_URL } from "./services";
import { HashRouter } from "react-router-dom/cjs/react-router-dom.min";

SwiperCore.use([Pagination]);
window.katex = katex;
const App = observer(() => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    i18n
      .use(Backend)

      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init(
        {
          lng: "en",
          // debug: true,
          fallbackLng: "en", // set to your project's base language

          ns: ["translation"], // namespaces you want to load
          defaultNS: "translation", // namespace that is your default

          supportedLngs: ["en"], // languages in your project

          backend: {
            loadPath: REACT_APP_API_URL + "translation",
          },
          react: {
            wait: true,
          },
        },
        function (err, t) {}
      )
      .then((res) => {
        setLoading(false);
      });
  }, []);
  return (
    <HashRouter>
      {loading ? (
        <div className="loading">
          <div className="indicator">
            <div className="loader"></div>
            <img src="/logo.png" alt="Loading..." className="logo"></img>
          </div>
        </div>
      ) : (
        <div className="App">
          <Header />

          <AppRouter />

          <div className="container">
            <HomeBookSlider />
            {/*<HomeNewsSlider />*/}
          </div>
          <Footer />
        </div>
      )}
    </HashRouter>
  );
});

export default App;

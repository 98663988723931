import i18next from "i18next";
import React, { Fragment, useEffect, useState }    from 'react';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { get_bnl_exam_result } from "../../../services/bnlApi";
import parse from "html-react-parser";
import Account from "../Account";

const BilExamResult = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { tur_id, lesson_id } = useParams();
  useEffect(() => {
    setLoading(true);
    
    get_bnl_exam_result('bil', tur_id, lesson_id).then((res) => {
      setData(res);
      setLoading(false);
    });
  }, []);
  return (
    <Fragment>
      <div className="main-title">{i18next.t("bil")}</div>
      <div id="LwaDetail" className="container">
        <h3 className="title">{i18next.t("bil_result")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="exams" />
          </div>
          <div className="col-xs-12 col-md-8" id="quiz">
            {data.map((v, index) => {
              return (
                <div className="question mb-2" key={index}>
                  <div className="q">
                    {index + 1}. {parse(v.content)}
                  </div>
                  <div className="answer">
                    <ul>
                      {v.answer.map((a) => (
                        <li
                          className={
                            a.is_checked === 1 && a.is_true === 1
                              ? "correct"
                              : a.is_checked == 1
                              ? "wrong"
                              : ""
                          }
                        >
                          <i className="bi-record-circle-fill"></i>
                          {a.content && parse(a.content)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BilExamResult;

import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import PlayList from "../../components/video/PlayList";
import { get_course } from "../../services/courseApi";
import MiniLoading from "../../components/MiniLoading";

import ReactHlsPlayer from 'react-hls-player';
import i18next from "i18next";




const CourseDetail = () => {
    const [course, setCourse] = useState(null);
    const [play, setPlay] = useState(null);
    const [loading, setLoading] = useState(false);
    let { id, play_id } = useParams();

    useEffect(() => {
        fetch_course();

    }, [id, play_id]);



    const fetch_course = async () => {
        setLoading(true);
        setPlay({});
        await get_course(id).then((res) => {
            res.list.map((item) => {
                if (item.id === parseInt(play_id)) {
                    setPlay(item);
                }
            });
            setCourse(res);
            setLoading(false);
        });
    };

    return (
        <div id="book" >
            <div className="container" >
                <div className="row" >
                    <div className="col-xs-12 col-md-8">
                        {
                            course == null ? <MiniLoading /> :
                                <Fragment>

                                    <h3>{course.title} </h3>

                                    <ReactHlsPlayer
                                        src={'https://qazbilim.online/api/video/' + play.code + '/playlist.m3u8'}
                                        autoplay={false}
                                        controls={true}
                                        width="100%"
                                        height="450"
                                    />


                                    <div className="TitleAndDate">
                                        <h3>{play.name} </h3>
                                        < p > {play.description} </p>
                                        < p className="date" >
                                            <span className="me-5">
                                                <i className="bi bi-eye" > </i> {course.video.views}
                                                {i18next.t("views")}
                                            </span>

                                            <span>
                                                <i className="bi bi-calendar" > </i> {course.video.updatedAt}
                                            </span>
                                        </p>
                                        < p > {course.video.description} </p>
                                    </div>

                                </Fragment>

                        }

                    </div>
                    <div className="col-xs-12 col-md-4" >
                        {course == null ? <MiniLoading /> : <PlayList
                            list={course.list}
                            play_id={play_id}
                            course_id={course.video.id}
                            is_buyed={course.video.is_buyed}
                        />}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default CourseDetail;
import i18next from "i18next";
import { useEffect, useState } from "react";
import React   from 'react';
import { useHistory } from "react-router-dom";
import CourselistFilter from "../../components/Course/CourseFilter";
import CourseListItem from "../../components/Course/CourseListItem";
import MiniLoading from "../../components/MiniLoading";
import { get_courses } from "../../services/courseApi";

const CourseList = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const fetch_data = async (params) => {
    let p = props.match.params;
    setLoading(true);
    await get_courses(p.sort, p.category, props.location.search.page).then(
      (res) => {
        setData(res);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const params = props.match.params;
    if (params.sort === undefined) {
      history.push("/course/new");
      fetch_data(params);
    } else {
      fetch_data(params);
    }
  }, [props]);
  return (
    <div id="videos">
      <div className="main-title">{i18next.t("ubt_title")}</div>
      <div className="container" id="books">
        <div className="row">
          <div className="col-xs-12">
            <h3 className="title">{i18next.t("Course List")}</h3>
          </div>
          <div className="col-xs-12 col-md-3">
            <CourselistFilter
              sort={props.match.params.sort}
              category={props.match.params.category}
            />
          </div>
          <div className="col-xs-12 col-md-9">
            <div className="row">
              { loading ? (
                <MiniLoading />
                ) : (data.data && data.data.map((video, index) => (
                        <div key={index} className="col-xs-12 col-md-3">
                            <CourseListItem item={video} />
                        </div>
                        ))) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseList;

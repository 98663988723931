import i18next from "i18next";
import { useEffect, useState } from "react";
import React    from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { get_lastest_books } from "../../services/bookApi";

import Item from "../book/Item";
import MiniLoading from "../MiniLoading";

const HomeBookSldier = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetch_data = async () => {
    setLoading(true);
    await get_lastest_books().then((res) => setData(res), setLoading(false));
  };
  useEffect(() => {
    fetch_data();
  }, []);
  return (
    <div id="home-sliders">
      <h3 className="title">{i18next.t("lastest_books")}</h3>
      {loading ? (
        <MiniLoading />
      ) : (
        <Swiper
          pagination={{
            clickable: true,
          }}
          spaceBetween={20}
          slidesPerView={4}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <Item item={item} hide_star={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default HomeBookSldier;



import { Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import React    from 'react';

const BilExamItem = (props) => {
  return (
    
            
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <Link
                    to={"/bnl/user-quiz-exam-result/bil/"+props.item.id+"/"+props.item.bnlQuizLessonId}
                  >
                    {props.item.lesson}
                  </Link>
                  <Badge variant="primary" className="float-right bg-gray1">
                     {props.item.point} 
                  </Badge>
                </ListGroupItem>
              </ListGroup>
  );
};

export default BilExamItem;

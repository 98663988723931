import i18next from "i18next";
import { Fragment } from "react";
import React from "react";
import Account from "./Account";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Exams = () => {
  const history = useHistory();
  return (
    <Fragment>
      <div className="main-title">{i18next.t("profile")}</div>
      <div id="Exams" className="container">
        <h3 className="title">{i18next.t("exams")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="exams" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div
                  className="education"
                  onClick={() => history.push("/user/exam/ubt")}
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist" />
                  </div>
                  {i18next.t("ubt")}
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div
                  className="education"
                  onClick={() => history.push("/user/exam/nzm")}
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist" />
                  </div>

                  {i18next.t("nzm")}
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div
                  className="education"
                  onClick={() => history.push("/user/exam/bil")}
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist" />
                  </div>
                  {i18next.t("bil")}
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div
                  className="education"
                  onClick={() => history.push("/user/exam/lvl")}
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist" />
                  </div>

                  {i18next.t("lvl")}
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div
                  className="education"
                  onClick={() => history.push("/user/exam/week")}
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist" />
                  </div>

                  {i18next.t("week")}
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <div
                  className="education"
                  onClick={() => history.push("/user/exam/accept")}
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist" />
                  </div>

                  {i18next.t("accept")}
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div
                  className="education"
                  onClick={() => history.push("/user/exam/creative")}
                >
                  <div className="icon">
                    <i className="bi bi-card-checklist" />
                  </div>

                  {i18next.t("creative")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Exams;

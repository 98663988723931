import { observer } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";
import React, { Fragment, useEffect, useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';

import {
  get_ubt_user_quiz,
  get_ubt_user_quiz_item_question,
  get_ubt_user_quiz_item_questions,
  post_finish_quiz,
  post_question_answer,
  post_question_answer_cross
} from "../../services/ubtApi";
import CountDown from "../../components/CountDown";
import swal from "sweetalert";
import i18next from "i18next";

const UbtQuiz = observer(() => {
  let history = useHistory();
  const [data, setData] = useState({
    lessons: [],
  });

  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState({});
  const params = useParams();
  const fetch_data = async () => {
    await get_ubt_user_quiz(params.token).then((res) => {
      if (res.expired) {
        swal("expired");
        // post_finish_quiz(params.token);
      } else {
        if (res.status === "error") {
          swal(i18next.t("error"), i18next.t(res.message), "error");
          history.push("/ubt");
        } else {
          setData(res);
          if (params.uqi_id === undefined) {
            window.location.href =
              "/#/ubt/quiz/" + params.token + "/" + res.lessons[0].id;
          }
        }
      }
    });
  };

  const submit_answer_cross = (item, answer_id) => {
        post_question_answer_cross(
          item.id,
          item.is_checked ? 0 : 1,
          answer_id
        ).then((res) => {
          if (res.status === "updated") {
            fetch_question(question.question.id);
        }
    });
  };

  const selectedOption = (id) => {
    var dd = question.answers.filter((i) => i.answer_id == id);
    if (dd.length > 0) {
      return parse(dd[0].content);
    }
    return "Таңдау";
  };
  const fetch_questions = async () => {
    await get_ubt_user_quiz_item_questions(params.token, params.uqi_id).then(
      (res) => {
        setQuestions(res);
        fetch_question(res[0].id);
      }
    );
  };

  const fetch_questions_reload = async () => {
    await get_ubt_user_quiz_item_questions(params.token, params.uqi_id).then(
      (res) => {
        setQuestions(res);
      }
    );
  };

  const fetch_question = async (uqiq_id) => {
    await get_ubt_user_quiz_item_question(uqiq_id).then((res) => {
      setQuestion(res);
      fetch_questions_reload();
    });
  };

  const submit_answer = (item) => {
    let c = question.answers.reduce((total, i) => {
      return total + (i.is_checked ? 1 : 0);
    }, 0);
    let max_choice = question.question.max_choice;
    if (max_choice > c) {
      post_question_answer(item.id, item.is_checked ? 0 : 1).then((res) => {
        if (res.status === "updated") {
          fetch_question(question.question.id);
        }
      });
    } else {
      if (item.is_checked) {
        post_question_answer(item.id, item.is_checked ? 0 : 1).then((res) => {
          if (res.status === "updated") {
            fetch_question(question.question.id);
          }
        });
      } else {
        swal(i18next.t("Info"), i18next.t("max_choice"), "info");
      }
    }
  };
  const finish_quiz = () => {
    post_finish_quiz(params.token).then((res) => {
      if (res.status === "finished") {
        history.push("/user/exam/ubt");
      }
    });
  };
  const fetch_init = () => {
    fetch_data();
    if (params.uqi_id) {
      fetch_questions();
    }
  };

  useEffect(() => {
    fetch_init();
  }, [params.uqi_id]);

  return (
    <div className="bg-gray">
      <div className="container" id="quiz">
        <div className="row">
          <div className="col col-xs-12 offset-xs-1 offset-md-2 col-md-8 align-self-center ">
            <div className="d-flex justify-content-between lessons">
              {data.lessons.map((item, index) => (
                <div
                  onClick={() => {
                    history.replace(
                      "/ubt/quiz/" + params.token + "/" + item.id
                    );
                  }}
                  className={
                    item.id === parseInt(params.uqi_id)
                      ? "active " + item.lesson_type
                      : item.lesson_type
                  }
                  key={index}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className="col col-xs-12 offset-xs-1 offset-md-2 col-md-8 align-self-center">
            <h4 className="name-time">
              {i18next.t("lesson_question")}
              <CountDown
                seconds={data.seconds}
                time_expired={() => finish_quiz()}
              />
            </h4>

            <div className="question">
              <h4>
                {i18next.t("question")}: {question.id}{" "}
                {question.question && question.question.q_type}
              </h4>
              <hr />
              <div className="q">
                {question.question && parse(question.question.content)}
              </div>
              <div className="answer">
                {question.question && question.question.q_type != "cross" ? (
                  <ul>
                    {question.answers &&
                      question.answers.map((item, index) => (
                        <li
                          key={index}
                          className={item.is_checked ? "check" : ""}
                          onClick={() => {
                            submit_answer(item);
                          }}
                        >
                          <i className="bi-record-circle-fill"></i>
                          {parse(item.content)}
                        </li>
                      ))}
                  </ul>
                ) : (
                  <ul>
                    {question.answers &&
                      question.answers
                        .filter((i) => i.col != 1)
                        .map((item, index) => (
                          <Fragment>
                            <li
                              key={index}
                              // className={item.is_checked ? "check" : ""}
                            >
                              <i className="bi-record-circle-fill"></i>
                              {parse(item.content)}
                            </li>
                            <DropdownButton 
                            className="mt-2 d-inline-block"
                            title={selectedOption(item.selected_answer_id)} 
                            variant="outline-secondary"
                            onSelect={(answer_id) =>submit_answer_cross(item, answer_id)}
                           

                            >
                               { question.answers && question.answers.filter((i) => i.col == 1)
                                                              .map((itemsub, index) => (
                                                                <Fragment key={"ss" + index}>
                                                                  <Dropdown.Item className="w-100" eventKey={itemsub.answer_id}>
                                                                    {parse(itemsub.content)}
                                                                </Dropdown.Item>
                                                                <Dropdown.Divider />
                                                              </Fragment>
                                                            ))}
                            </DropdownButton>
                          </Fragment>
                        ))}
                  </ul>
                )}
              </div>
              <div className="action">
                <button
                  className="btn btn-white btn-prev"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < questions.length; i++) {
                      const el = questions[i];
                      if (el.id === question.question.id) {
                        if (questions[i - 1] !== undefined) {
                          index = i - 1;
                        }
                      }
                    }
                    if (index !== -1) {
                      fetch_question(questions[index].id);
                    }
                  }}
                >
                  {i18next.t("prev")}
                </button>
                <button
                  className="btn btn-white btn-next"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < questions.length; i++) {
                      const el = questions[i];
                      if (el.id === question.question.id) {
                        if (questions[i + 1] !== undefined) {
                          index = i + 1;
                        }
                      }
                    }

                    if (index !== -1) {
                      fetch_question(questions[index].id);
                    }
                  }}
                >
                  {i18next.t("next")}
                </button>
                <button
                  className="btn btn-white btn-finish"
                  onClick={() => {
                    swal({
                      title: i18next.t("warning?"),
                      text: i18next.t("finishe_quiz?"),
                      icon: "warning",
                      buttons: {
                        cancel: i18next.t("cancel"),
                        ok: {
                          text: i18next.t("ok"),
                          value: true,
                        },
                      },
                      dangerMode: true,
                    }).then((result) => {
                      if (result) {
                        finish_quiz();
                      }
                    });
                    // finish_quiz();
                  }}
                >
                  {i18next.t("finish")}
                </button>
              </div>
            </div>

            <div className="page_num">
              {questions.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={
                      question.question && item.id === question.question.id
                        ? "op75 item active"
                        : "op75 item " + (item.total > 0 ? "checked" : "")
                    }
                    onClick={() => {
                      fetch_question(item.id);
                    }}
                  >
                    {index + 1}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UbtQuiz;

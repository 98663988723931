import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";

import i18next from "i18next";
import {
  get_cart,
  inc_cart_item,
  remove_cart_item,
  checkout_cart,
} from "../../services/userApi";
import { REACT_APP_API_URL } from "../../services";
import { Context } from "../..";
import swal from "@sweetalert/with-react";

const UserCart = observer((props) => {
  const [data, setData] = useState([]);
  const { user } = useContext(Context);
  useEffect(() => {
    fetch_data();
  }, []);

  const fetch_data = () => {
    get_cart().then((res) => {
      setData(res);
    });
  };
  const checkout = () => {
    checkout_cart().then((rs) => {
      if (rs.status == "success") {
        swal(rs.message);
        user.setCartCount(rs.count);
        window.location.href = "/";
      } else {
        swal(rs.message);
      }
    });
  };

  const change = (el, action) => {
    if (action == "minus" && el.qty <= 1) {
      swal(i18next.t("qty min 1"));
    } else {
      inc_cart_item(el.id, action == "minus" ? el.qty - 1 : el.qty + 1).then(
        (rs) => {
          if (rs.status == "success") {
            user.setCartCount(rs.count);
          }
          fetch_data();
        }
      );
    }
  };
  const deleteItem = (el) => {
    remove_cart_item(el.id).then((rs) => {
      if (rs.status == "success") {
        user.setCartCount(rs.count);
      }
      fetch_data();
    });
  };

  const get_deliver_price = () => {
    console.log(data);
    var exists = data.reduce(
      (t, el, index) => t + (el.delivery_address.length > 0 ? 1 : 0),
      0
    );
    return exists > 0 ? 2000 : 0;
  };
  const get_total = () => {
    return data.reduce(
      (t, el, index) =>
        t + el.qty * (el.item.discount > 0 ? el.item.discount : el.item.price),
      0
    );
  };

  return (
    <div className="justify-content-center mt-2">
      <Card className="w-75 p-3" style={{ margin: "auto" }}>
        <Card.Title className="title title-modal w-100">
          {i18next.t("User Cart")}
        </Card.Title>

        <Card.Body className="d-grid gap-2 ">
          <Table>
            <thead>
              <tr>
                <th>{i18next.t("photo")}</th>
                <th>{i18next.t("title")}</th>
                <th>{i18next.t("qty")}</th>
                <th>{i18next.t("price")}</th>
                <th>{i18next.t("total")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((el) => (
                <tr key={el.item.id}>
                  <td width={80}>
                    <img
                      src={REACT_APP_API_URL + el.item.cover_path}
                      alt={el.item.title}
                      height="120"
                    />
                  </td>
                  <td>
                    <span className="badge badge-primary">
                      {i18next.t(el.source)}
                    </span>
                    <br />
                    {el.item.title}
                  </td>
                  <td>
                    {el.source == "book" ? (
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() => change(el, "minus")}
                      >
                        -
                      </button>
                    ) : (
                      ""
                    )}

                    <span className="m-2">{el.qty}</span>
                    {el.source == "book" ? (
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() => change(el, "plus")}
                      >
                        +
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {el.item.discount > 0 ? (
                      <Fragment>
                        <span
                          style={{
                            "text-decoration": "line-through",
                            color: "#ccc",
                          }}
                        >
                          {el.item.price}₸
                        </span>
                        <br />
                      </Fragment>
                    ) : (
                      ""
                    )}
                    {el.item.discount > 0 ? el.item.discount : el.item.price}₸
                  </td>
                  <td>
                    {(el.item.discount > 0 ? el.item.discount : el.item.price) *
                      el.qty}
                    ₸
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => deleteItem(el)}
                    >
                      {i18next.t("delete")}
                    </button>
                  </td>
                </tr>
              ))}
              <tr></tr>
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3}></td>
                <td>
                  <b>{i18next.t("Delivery Price")}</b>
                </td>
                <td>
                  <b>+{get_deliver_price()}₸</b>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={3}></td>
                <td>
                  <b>{i18next.t("total")}</b>
                </td>
                <td>
                  <b>{get_total() + get_deliver_price()}₸</b>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={5}></td>

                <td>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => checkout()}
                  >
                    {i18next.t("checkout cart")}
                  </button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
});

export default UserCart;

import React, { Fragment, useEffect, useState } from "react";
import CourseListItem from "../../components/Course/CourseListItem";
import { my_course } from "../../services/userApi";
import Account from "./Account";

const Courses = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetch_my_course = async () => {
    setLoading(true);
    await my_course().then((res) => {
      setData(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch_my_course();
  }, []);
  return (
    <Fragment>
      <div className="main-title">Courses</div>
      <div id="Courses" className="container">
        <h3 className="title">Your Account</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="courses" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              {data.map((item, index) => (
                <div className="col-xs-12 col-md-6">
                  <CourseListItem item={item} key={index} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Courses;

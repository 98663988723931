import i18next from "i18next";
import { useEffect, useState } from "react";
import React    from 'react';
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import { latest_news } from "../services/publicApi";
import {REACT_APP_API_URL} from "../services/";

const LatestNews = () => {
  const [data, setData] = useState([]);
  const fetch_data = () => {
    latest_news().then((res) => setData(res));
  };
  useEffect(() => {
    fetch_data();
  }, []);
  return (
    <div id="news">
      <h3 className="news-title">{i18next.t("Latest News")}</h3>
      <div className="row">
        <div className="col-xs-12">
          {data.map((item, index) => (
            <div className="card news-item" key={index}>
              <Link to={"/news/" + item.id}>
                <div
                  className="cover"
                  style={{
                      backgroundImage: `url(${REACT_APP_API_URL}${item.cover_path})`,
                  }}
                ></div>
              </Link>

              <div className="title-news">
                <Link to={"/news/" + item.id}>{item.title}</Link>
              </div>

              <div className="short-news">
                <Link to={"/news/" + item.id}>{parse(item.short)}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;

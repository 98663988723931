import i18next from "i18next";
import React, { useState } from "react";

import ListItem from "./ListItem";

const RelatedBooks = () => {
  const [books, setBooks] = useState([]);
  return (
    <div className="container">
      {books.length > 0 ? (
        <div className="row">
          <div className="col-xs-12">
            <h3 className="title">{i18next.t("related_books")}</h3>
          </div>
          {books.map((item, index) => (
            <div key={index} className="col-xs-12 col-md-3">
              <ListItem item={item} />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default RelatedBooks;

import React, { Fragment } from "react";
import About from "../components/home/About";
import Slider from "../components/home/Slider";
import Quiz from "../components/home/Quiz";
import StaticCourse from "../components/home/StaticCourse";
const Home = () => {
  return (
    <Fragment>
      <Slider />
      {/* <About /> */}
      <StaticCourse />
      <Quiz />
      <div className="container">
        {/* <About />
        <StaticCourse /> */}
        {/* <Quiz />
        <Books />
        <News />
        <Home1 /> */}
      </div>
    </Fragment>
  );
};
export default Home;

import { Button } from "bootstrap";
import { useState } from "react";
import { Card, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import React from "react";
import i18next from "i18next";
import swal from "@sweetalert/with-react";
import { forget_password } from "../services/userApi";
const ForgotForm = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    forget_password({ email }).then((res) => {
      swal(i18next.t(res.message));
    });
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md="4">
          <Card>
            <Card.Header> {i18next.t("Reset Password")}</Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Form.Label htmlFor="email">{i18next.t("Email")}:</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormGroup>
                <FormGroup className="mt-3">
                  <button type="submit" className="btn btn-primary">
                    {i18next.t("Send password reset email")}
                  </button>
                </FormGroup>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default ForgotForm;

import swal from "@sweetalert/with-react";
import i18next from "i18next";
import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect, useState } from "react";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Context } from "..";
import { registration, schools } from "../services/userApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const SignUp = observer((props) => {
  const { user } = useContext(Context);
  const [first_name, setFirstName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirm_password, setConfirmPassword] = useState(null);
  const [school, setSchool] = useState(null);
  const [class_level, setClassLevel] = useState(null);
  const [school_data, setSchoolData] = useState([]);
  const [errors, setErrors] = useState({});
  let history = useHistory();

  const SignUpFn = async () => {

    var vm = this;
    await registration({
      first_name,
      last_name,
      email,
      phone,
      password,
      password_confirmation: confirm_password,
      school,
      class_level,
    })
      .then((res) => {
        if (res.status === "success") {
          props.reset();
          swal(i18next.t(res.message), "", "success");
          
        } else {
          swal(i18next.t(res.message), "", "error");
        }
      })
      .catch((err) => {
        swal(err, "", "error");
      });
  };
  useEffect(() => {
    schools().then((res) => setSchoolData(res));
  }, []);
  return (
    <Fragment>
      <Modal.Header className="flex-column">
        <img src="/logo.png" alt="..." className="logo-modal " />
        <Modal.Title className="title title-modal w-100">
          {i18next.t("Sign Up")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-grid gap-2 ">
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={i18next.t("first_name")}
            className={errors.first_name ? "is-invalid" : ""}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={i18next.t("last_name")}
            className={errors.last_name ? "is-invalid" : ""}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="email"
            placeholder={i18next.t("email")}
            className={errors.email ? "is-invalid" : ""}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            placeholder={i18next.t("phone")}
            className={errors.phone ? "is-invalid" : ""}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            placeholder={i18next.t("password")}
            className={errors.password ? "is-invalid" : ""}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="password"
            placeholder={i18next.t("confirm_password")}
            className={errors.confirm_password ? "is-invalid" : ""}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control as="select" onChange={(e) => setSchool(e.target.value)}>
            <option value={null}>{i18next.t("select school")}</option>
            {school_data.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </Form.Control>
          <Form.Text className="text-muted">
            {i18next.t("school_text")}
          </Form.Text>
        </Form.Group>

        <Form.Group>
          <Form.Control
            as="select"
            onChange={(e) => setClassLevel(e.target.value)}
          >
            <option value={null}>{i18next.t("class level")}</option>
            {[5, 6, 7, 8, 9, 10, 11].map((e) => (
              <option key={e} value={e} selected={e == class_level}>
                {e}
              </option>
            ))}
          </Form.Control>
          <Form.Text className="text-muted">
            {i18next.t("class_level_text")}
          </Form.Text>
        </Form.Group>

        <Button
          className="button-success  w-100 "
          variant="success"
          onClick={() => {
            SignUpFn();
          }}
        >
          {i18next.t("Sign Up")}
        </Button>
      </Modal.Body>
    </Fragment>
  );
});
export default SignUp;

import React, { createContext } from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "swiper/swiper.min.css";
import "./App.css";
// import { icons } from "./assets/icons";
import App from "./App";
import Store from "./Store";
// React.icons = icons;

// import i18n from "./i18n";

export const Context = createContext(null);
ReactDOM.render(
  <Context.Provider value={{ user: new Store() }}>
    <App />
  </Context.Provider>,
  document.getElementById("root")
);

import React, { Fragment, useEffect, useId, useState } from "react";
import Account from "./Account";

import i18next from "i18next";
import { get_user_info } from "../../services/userApi";
import axios from "axios";

const Charge = () => {
  const [amount, setAmount] = useState(0);
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    get_info();
  }, []);

  const get_info = async () => {
    await get_user_info().then((res) => {
      setUser(res);
    });
  };

//   const getQR = () => {
// let a = axios.create({
//   verifySSL: false,
// })
//     a.post('https://kaspi.kz/online', {
//       "TranId": parseInt(Math.random() * 1000000).toString(),
//       "OrderId": user.unumber,
//       "Amount": (amount * 100).toString(),
//       "Service": "qazbilimmektebi",
//       "returnUrl": "https://qazbilim.online/kasp/result",
//       "refererHost": "https://qazbilim.online",
//       "GenerateQrCode": true
//     }).then((res) => {
//       setData(res.data);
//     }, {
     
//       'Access-Control-Allow-Origin': '*',
//       'Content-Type': 'application/json',
//     }).catch(function (error) {
//       if (error.response) {
//         console.log(error.response.headers);
//       }
//       else if (error.request) {
//         console.log(error.request);
//       }
//       else {
//         console.log(error.message);
//       }
//       console.log(error.config);
//     });

//   }
  return (
    <Fragment>
      <div className="main-title">{i18next.t("Charge account")}</div>
      <div id="Charge" className="container">
        <h3 className="title">{i18next.t("Charge account")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="charge" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              {
                data != null ? <div className="text-center">
                  <h3>{amount}</h3>
                  <img src={"data:image/jpeg;base64," + data.qrCodeImage} alt="QR" />
                </div> : <table>
                  <tr>
                    <td align="center">
                      <img
                        src="https://www.nio.kz/images/kaspi.png"
                        height="80"
                      />
                      <p>{i18next.t("Kaspi bank")}</p>
                    </td>
                    <td>
                      <ul>
                        <li>Kaspi.kz сайтына өтіп, жеке бетіңізге кіріңіз</li>
                        <li>
                          {" "}
                          бетін ашып, тізім арасынан qazbilim.online-ті таңдаңыз
                          «ID» жолына ID
                        </li>
                        <li>
                          нөмерін енгізің Төлеу қажет сомманы енгізіп, «Төлеу-ді»
                        </li>
                        <li> басыңыз</li>
                      </ul>
                    </td>
                    <td>


                      {/* <input
                        placeholder={i18next.t("amount")}
                        onChange={(e) => setAmount(e.target.value)}
                        className="form-control"
                        type="number"
                        name="Amount"
                        id="amount"
                      />

                      <button className="btn btn-success w-100 mt-3"
                        onClick={() => getQR()}>{i18next.t("submit payment")}</button> */}


                      <form action="https://kaspi.kz/online" method="post" id="kaspikz-form">
                        <input type="hidden" name="TranId" value={`${Math.random()*1000000}`} />
                        <input type="hidden" name="OrderId" value={`${user!=null ? user.unumber:0}`} />
                        <input type="hidden" name="Amount" value={amount*100} />
                        <input
                          placeholder={i18next.t("amount")}
                          onChange={(e) => setAmount(e.target.value)}
                          className="form-control"
                          type="text"
                        />
                        <input type="hidden" name="Service" value="qazbilimmektebi" />
                        <input type="hidden" name="returnUrl" value="https://qazbilim.online/kasp/result" />
                        <button className="btn btn-success w-100 mt-3"
                          onclick="submitForm();">{i18next.t("submit payment")}</button>
                      </form>


                    </td>
                  </tr>
                </table>
              }

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Charge;

import swal from "@sweetalert/with-react";
import { Modal } from "react-bootstrap";
import i18next from "i18next";
import React, { useState } from "react";
import {
  answer_update,
  buy_video,
  get_question,
  get_questions,
  post_finish,
} from "../../services/courseApi";
import PlayListItem from "./PlayListItem";
import parse from "html-react-parser";
import CountDown from "../CountDown";

const PlayList = (props) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show_result, setShowResult] = useState(false);
  const [quiz_questions, setQuizQuestions] = useState([]);
  const [question, setQuestion] = useState({});
  const [seconds, setSeconds] = useState(null);
  const [uqid, setUsid] = useState(0);
  const [more, setMore] = useState("320px");
  const _buy_video = async () => {
    if (props.course_id) {
      setLoading(true);
      await buy_video(props.course_id).then((res) => {
        if (res.status === "success") {
          window.location.reload();
        } else {
          swal(i18next.t(res.message), "", "error");
        }
        setLoading(false);
      });
    }
  };

  const _get_question = async (id, user_quiz_id) => {
    setLoading(true);
    await get_question(id, user_quiz_id).then((res) => {
      setQuestion(res);
      setLoading(false);
    });
  };

  const take_quiz = async (quiz_id, user_quiz_id) => {
    setShow(true);
    setLoading(true);
    await get_questions(quiz_id, user_quiz_id ? user_quiz_id : 0).then(
      (res) => {
        setQuizQuestions(res.data);
        setUsid(res.data[0].vVideoUserQuizId);
        setSeconds(res.seconds);
        _get_question(res.data[0].id, res.data[0].vVideoUserQuizId);
        setLoading(false);
      }
    );
  };

  const quiz_result = async (quiz_id, user_quiz_id) => {
    setShowResult(true);
    setLoading(true);
    await get_questions(quiz_id, user_quiz_id ? user_quiz_id : 0).then(
      (res) => {
        setQuizQuestions(res.data);
        setUsid(res.data[0].vVideoUserQuizId);
        setSeconds(res.seconds);
        _get_question(res.data[0].id, res.data[0].vVideoUserQuizId);
        setLoading(false);
      }
    );
  };

  const submit_answer = async (item) => {
    setLoading(true);
    let c = question.answers.reduce((total, i) => {
      return total + (i.is_checked ? 1 : 0);
    }, 0);
    let max_choice = question.max_choice;
    if (c < max_choice) {
      item.is_checked = 1;
      await answer_update(item.id, item.is_checked).then((res) => {
        if (res.status === "updated") {
          _get_question(question.id, question.vVideoUserQuizId);
        }
        setLoading(false);
      });
    } else {
      if (item.is_checked === 1) {
        item.is_checked = 0;
        await answer_update(item.id, item.is_checked).then((res) => {
          if (res.status === "updated") {
            _get_question(question.id, question.vVideoUserQuizId);
          }
          setLoading(false);
        });
      } else {
        swal(i18next.t("Info"), i18next.t("max_choice"), "info");
      }
    }
  };
  const finish_quiz = async () => {
    setLoading(true);
    await post_finish(uqid).then((res) => {
      if (res.status === "finished") {
        window.location.reload();
      }
    });
  };
  return (
    <div id="playlist">
      <div className="p-2">
        {props.is_buyed === false ? (
          loading ? (
            <button className="btn btn-blue btn-info w-100">
              {i18next.t("loading...")}
            </button>
          ) : (
            <button
              className="btn btn-blue btn-primary w-100"
              onClick={() => {
                _buy_video();
              }}
            >
              {i18next.t("buy_video")}
            </button>
          )
        ) : (
          ""
        )}
      </div>
      <div className="d-flex">
        <div className="mr-auto p-2">{i18next.t("Play list")}</div>
        <div className="p-2 ">
          <i className="bi bi-play-circle"></i>
        </div>
        {/* <div className="autoplay p-2">Autoplay</div> */}
      </div>
      <ul
        className="list-unstyled"
        style={
          more === "auto" ? {} : { maxHeight: "320px", overflow: "scroll" }
        }
      >
        {props.list.map((item, index) => (
          <PlayListItem
            key={index}
            item={item}
            course_id={props.course_id}
            callback={take_quiz}
            callback_result={quiz_result}
          />
        ))}
      </ul>
      <center>
        <button
          onClick={() => {
            if (more === "auto") {
              setMore("320px");
            } else {
              setMore("auto");
            }
          }}
          className="btn btn-sm  btn-info"
        >
          {i18next.t(more === "auto" ? "Hide" : "See all")}
        </button>
      </center>

      <Modal
        show={show}
        backdrop="static"
        size="lg"
        onHide={() => {
          setShow(false);
          setLoading(false);
        }}
      >
        <Modal.Header>
          <h4>
            {i18next.t("question")}: {question.id}
          </h4>
          <CountDown seconds={seconds} time_expired={() => finish_quiz()} />
        </Modal.Header>
        <Modal.Body id="quiz">
          {loading ? (
            i18next.t("loading ...")
          ) : (
            <div className="question">
              <div className="q">
                {question.content && parse(question.content)}
              </div>
              <div className="answer">
                <ul>
                  {question.answers &&
                    question.answers.map((answer, index) => (
                      <li
                        key={index}
                        className={answer.is_checked === 1 ? "check" : ""}
                        onClick={() => {
                          submit_answer(answer);
                        }}
                      >
                        <i className="bi-record-circle-fill"></i>
                        {answer.content && parse(answer.content)}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="action">
                <button
                  className="btn btn-white btn-prev"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < quiz_questions.length; i++) {
                      const el = quiz_questions[i];
                      if (el.id === question.id) {
                        if (quiz_questions[i - 1] !== undefined) {
                          index = i - 1;
                        } else {
                          alert(i18next.t("first_question"));
                        }
                      }
                    }
                    if (index !== -1) {
                      _get_question(
                        quiz_questions[index].id,
                        quiz_questions[index].vVideoUserQuizId
                      );
                    }
                  }}
                >
                  {i18next.t("prev")}
                </button>
                <button
                  className="btn btn-white btn-next"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < quiz_questions.length; i++) {
                      const el = quiz_questions[i];
                      if (el.id === question.id) {
                        if (quiz_questions[i + 1] !== undefined) {
                          index = i + 1;
                        } else {
                          alert(i18next.t("last_question"));
                        }
                      }
                    }

                    if (index !== -1) {
                      _get_question(
                        quiz_questions[index].id,
                        quiz_questions[index].vVideoUserQuizId
                      );
                    }
                  }}
                >
                  {i18next.t("next")}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            className="btn btn-default"
            onClick={() => {
              setShow(false);
              setLoading(false);
            }}
          >
            {i18next.t("close")}
          </button>
          <button
            className="btn btn-warning"
            onClick={() => {
              finish_quiz();
            }}
          >
            {i18next.t("finish_video_quiz")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show_result}
        backdrop="static"
        size="lg"
        onHide={() => {
          setShowResult(false);
          setLoading(false);
        }}
      >
        <Modal.Header>
          <h4>
            {i18next.t("question")}: {question.id}
          </h4>
        </Modal.Header>
        <Modal.Body id="quiz">
          {loading ? (
            i18next.t("loading ...")
          ) : (
            <div className="question">
              <div className="q">
                {question.content && parse(question.content)}
              </div>
              <div className="answer">
                <ul>
                  {question.answers &&
                    question.answers.map((answer, index) => (
                      <li
                        key={index}
                        className={
                          answer.is_checked === 1
                            ? "check " +
                              (answer.is_true == 1 ? "correct" : "wrong")
                            : ""
                        }
                      >
                        <i className="bi-record-circle-fill"></i>
                        {answer.content && parse(answer.content)}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="action">
                <button
                  className="btn btn-white btn-prev"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < quiz_questions.length; i++) {
                      const el = quiz_questions[i];
                      if (el.id === question.id) {
                        if (quiz_questions[i - 1] !== undefined) {
                          index = i - 1;
                        } else {
                          alert(i18next.t("first_question"));
                        }
                      }
                    }
                    if (index !== -1) {
                      _get_question(
                        quiz_questions[index].id,
                        quiz_questions[index].vVideoUserQuizId
                      );
                    }
                  }}
                >
                  {i18next.t("prev")}
                </button>
                <button
                  className="btn btn-white btn-next"
                  onClick={() => {
                    let index = -1;
                    for (let i = 0; i < quiz_questions.length; i++) {
                      const el = quiz_questions[i];
                      if (el.id === question.id) {
                        if (quiz_questions[i + 1] !== undefined) {
                          index = i + 1;
                        } else {
                          alert(i18next.t("last_question"));
                        }
                      }
                    }

                    if (index !== -1) {
                      _get_question(
                        quiz_questions[index].id,
                        quiz_questions[index].vVideoUserQuizId
                      );
                    }
                  }}
                >
                  {i18next.t("next")}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            className="btn btn-default"
            onClick={() => {
              setShowResult(false);
              setLoading(false);
            }}
          >
            {i18next.t("close")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PlayList;

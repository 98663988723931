import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";

import i18next from "i18next";
import MiniLoading from "../components/MiniLoading";
import { top25 } from "../services/publicApi";
const Top25 = () => {
  const [quizz, setQuizz] = useState("ubt");
  const [range, setRange] = useState("today");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchData = () => {
    setLoading(true);
    top25(quizz, range).then((res) => {
      setData(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, [quizz, range]);
  return (
    <Fragment>
      <Container className="mt-3">
        <Row>
          <Card>
            <Card.Body className="d-inline">
              <Row>
                <Col sm={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        setQuizz(e.target.value);
                      }}
                    >
                      <option value={"ubt"} selected={"ubt" == quizz}>
                        {i18next.t("ubt")}
                      </option>
                      <option value={"nzm"} selected={"nzm" == quizz}>
                        {i18next.t("nzm")}
                      </option>
                      <option value={"bil"} selected={"bil" == quizz}>
                        {i18next.t("bil")}
                      </option>
                      <option value={"level"} selected={"level" == quizz}>
                        {i18next.t("level")}
                      </option>
                      <option value={"week"} selected={"week" == quizz}>
                        {i18next.t("week")}
                      </option>
                      <option value={"accept"} selected={"accept" == quizz}>
                        {i18next.t("accept")}
                      </option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                      {i18next.t("select_quiz_text")}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        setRange(e.target.value);
                      }}
                    >
                      <option value={"today"} selected={"today" == range}>
                        {i18next.t("today")}
                      </option>
                      <option value={"week"} selected={"week" == range}>
                        {i18next.t("weekly")}
                      </option>
                      <option value={"month"} selected={"month" == range}>
                        {i18next.t("month")}
                      </option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                      {i18next.t("select_range_text")}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <Card className="mt-2">
            <Card.Body>
              {loading ? (
                <MiniLoading />
              ) : (
                <Table>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>{i18next.t("Name and School")}</th>
                      <th>{i18next.t("max_total_point")}</th>
                      <th>{i18next.t("total_point")}</th>
                      <th>{i18next.t("percent")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((e, i) => (
                      <tr key={i} className={"place" + (i + 1)}>
                        <td>{i + 1}</td>
                        <td>
                          {e.last_name} {e.first_name}
                        </td>
                        <td>{e.max_point}</td>
                        <td>{e.total_point}</td>
                        <td>
                          {Number.parseFloat(
                            (e.total_point * 100) / e.max_point
                          ).toFixed(2)}
                          %
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Top25;

import Login from "./pages/Login";
import Register from "./pages/Register";
import Bil from "./pages/bil";
import BookDetail from "./pages/books/BoookDetail";
import List from "./pages/books/List";
import CourseDetail from "./pages/course/course_detail";
import CourseList from "./pages/course/CourseList";
import Home from "./pages/Home";
import Nzm from "./pages/nzm";
import About from "./pages/About";
import Ubt from "./pages/ubt";
import UbtQuiz from "./pages/ubt/UbtQuiz";
import Profile from "./pages/user/Profile";
import NewsList from "./pages/news/NewsList";
import NewsDetail from "./pages/news/NewsDetail";
import NZMQuiz from "./pages/nzm/NZMQuiz";
import Charge from "./pages/user/Charge";
import Books from "./pages/user/Books";
import Courses from "./pages/user/Courses";
import Exams from "./pages/user/Exams";
import PaymentHistory from "./pages/user/PaymentHistory";
import UbtExams from "./pages/user/ubt/UbtExams";
import UbtExamResult from "./pages/user/ubt/UbtExamResult";
import NzmExams from "./pages/user/nzm/NzmExams";
import BilExams from "./pages/user/bil/BilExams";
import Lvl from "./pages/lvl";
import LVLQuiz from "./pages/lvl/LVLQuiz";
import WeekQuiz from "./pages/week/WeekQuiz";
import BILQuiz from "./pages/bil/BILQuiz";
import Week from "./pages/week";
import Accept from "./pages/accept";
import AcceptQuiz from "./pages/accept/AcceptQuiz";
import LwaExams from "./pages/user/lwa";
import LwaDetail from "./pages/user/lwa/detail";
import NzmExamResult from "./pages/user/nzm/NzmExamResult";

import ChangePassword from "./pages/user/ChangePassword";
import UserCart from "./pages/user/UserCart";
import Top25 from "./pages/top25";
import ResetForm from "./pages/ResetForm";
import ForgotForm from "./pages/ForgotForm";
import BilExamResult from "./pages/user/bil/BilExamResult";
import Creative from "./pages/creative";
import CreativeQuiz from "./pages/creative/quiz";

import CreativeExams from "./pages/user/creative/CreativeExams";
import CreativeExamResult from "./pages/user/creative/CreativeExamResult";


export const privateRoutes = [
  {
    path: "/profile",
    Component: Profile,
  },
  {
    path: "/user/cart",
    Component: UserCart,
  },
  {
    path: "/user/profile",
    Component: Profile,
  },
  {
    path: "/user/charge",
    Component: Charge,
  },
  {
    path: "/user/books",
    Component: Books,
  },
  {
    path: "/user/courses",
    Component: Courses,
  },
  {
    path: "/user/exams",
    Component: Exams,
  },
  {
    path: "/user/exam/ubt",
    Component: UbtExams,
  },
  {
    path: "/user/exam/ubt/result/:token/:item_id",
    Component: UbtExamResult,
  },
  {
    path: "/user/exam/nzm",
    Component: NzmExams,
  },
  {
    path: "/user/change-password",
    Component: ChangePassword,
  },
  {
    path: "/bnl/user-quiz-exam-result/:tur_id/:lesson_id",
    Component: NzmExamResult,
  },
  {
    path: "/bnl/user-quiz-exam-result/bil/:tur_id/:lesson_id",
    Component: BilExamResult,
  },
  {
    path: "/user/exam/bil",
    Component: BilExams,
  },
  {
    path: "/user/exam/creative/result/:token/:item_id",
    Component: CreativeExamResult,
  },
  {
    path: "/user/exam/creative",
    Component: CreativeExams,
  },
  {
    path: "/user/exam/:lwa",
    Component: LwaExams,
  },
  {
    path: "/user/exam/:lwa/:token",
    Component: LwaDetail,
  },
 
  {
    path: "/user/history",
    Component: PaymentHistory,
  },
  
];
export const publicRoutes = [
  {
    path: "/",
    Component: Home,
  },
  {
    path: "/auth/forgot-password",
    Component: ForgotForm,
  },
  {
    path: "/auth/reset-password",
    Component: ResetForm,
  },
  {
    path: "/top25",
    Component: Top25,
  },
  {
    path: "/auth/login",
    Component: Login,
  },
  {
    path: "/auth/register",
    Component: Register,
  },
  {
    path: "/ubt",
    Component: Ubt,
  },
  {
    path: "/ubt/quiz/:token/:uqi_id?/:uqiq_id?",
    Component: UbtQuiz,
  },
  {
    path: "/nzm",
    Component: Nzm,
  },
  {
    path: "/bnl/quiz/nzm/:token/:lesson?/:tur?",
    Component: NZMQuiz,
  },
  {
    path: "/bil",
    Component: Bil,
  },
  {
    path: "/bnl/quiz/bil/:token/:lesson?/:tur?",
    Component: BILQuiz,
  },
  {
    path: "/level",
    Component: Lvl,
  },
  {
    path: "/level/quiz/:token",
    Component: LVLQuiz,
  },
  {
    path: "/week",
    Component: Week,
  },
  {
    path: "/week/quiz/:token",
    Component: WeekQuiz,
  },
  {
    path: "/accept",
    Component: Accept,
  },
  {
    path: "/accept/quiz/:token",
    Component: AcceptQuiz,
  },
  {
    path: "/books/:sort?/:category?",
    Component: List,
  },
  {
    path: "/course/:sort?/:category?",
    Component: CourseList,
  },
  {
    path: "/book-detail/:id",
    Component: BookDetail,
  },
  {
    path: "/course-detail/:id/play/:play_id",
    Component: CourseDetail,
  },

  {
    path: "/about",
    Component: About,
  },
  {
    path: "/news",
    Component: NewsList,
  },
  {
    path: "/news/:id",
    Component: NewsDetail,
  },
  {
    path: "/creative",
    Component: Creative,
  },
  {
    path: "/creative/quiz/:token/:uqi_id?/:uqiq_id?",
    Component: CreativeQuiz,
  },
];

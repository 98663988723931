import { $authHost, $host } from ".";

export const get_bnl_lessons = async (quiz_type) => {
  const { data } = await $host.get("/bnl/lessons/" + quiz_type);
  return data;
};

export const buy_quiz = async (quiz_type) => {
  const { data } = await $authHost.post("/bnl/buy_quiz/" + quiz_type);
  return data;
};

export const user_quiz_tur = async (quiz_type, token) => {
  const { data } = await $authHost.get(
    "/bnl/user-quiz-tur/" + quiz_type + "/" + token
  );
  return data;
};

export const user_quiz_tur_lesson_questions = async (
  quiz_type,
  tur_id,
  lesson
) => {
  const { data } = await $authHost.get(
    "/bnl/get-lesson-checked-questions/" +
      quiz_type +
      "/" +
      tur_id +
      "/" +
      lesson
  );
  return data;
};

export const user_quiz_tur_lesson_question = async (quiz_type, id) => {
  const { data } = await $authHost.get(
    "/bnl/user-quiz-tur-lesson-question/" + quiz_type + "/" + id
  );
  return data;
};

export const post_bnl_question = async (quiz_type, id, is_true) => {
  const { data } = await $authHost.post(
    "/bnl/post-bnl-question-answer/" + id + "/" + is_true
  );
  return data;
};

export const post_finish_quiz = async (token) => {
  const { data } = await $authHost.post("/bnl/finish/" + token );
  return data;
};

export const post_finish_quiz_tur1 = async (token1) => {
  const { data } = await $authHost.post("/bnl/finish-tur1/" + token1);
  return data;
};

export const get_bnl_exams = async (quiz_type) => {
  const { data } = await $authHost.get("/bnl/user-quiz/" + quiz_type);
  
  return data;
};

export const get_bnl_exam_result = async (quiz_type, tur_id, lesson_id) => {
  const { data } = await $authHost.get(
    "/bnl/user-quiz-result/" + quiz_type + "/" + tur_id + "/" + lesson_id
  );
  return data;
};

export const get_bnl_checked_questions = async (quiz_type, token, lesson_id) => {
  const { data } = await $authHost.get(
    "/bnl/get-lesson-checked-questions/" + quiz_type + "/" + token + "/" + lesson_id
  );
  return data;
};

import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";

export const registration = async (form) => {
  const { data } = await $host.post("/auth/registration", form);
  return data;
};

export const forget_password = async (form) => {
  const { data } = await $host.post("/auth/forgot-password", form);
  return data;
};

export const reset_password = async (form) => {
  const { data } = await $host.post("/auth/reset-password", form);
  return data;
};
export const change_password = async (form) => {
  const { data } = await $authHost.post("/auth/change-password", form);
  return data;
};

export const add_to_cart = async (source, source_id, form) => {
  const { data } = await $authHost.post(
    "/auth/add-cart/" + source + "/" + source_id,
    form
  );
  return data;
};

export const checkout_cart = async () => {
  const { data } = await $authHost.post("/auth/cart-checkout", {});
  return data;
};

export const get_cart = async () => {
  const { data } = await $authHost.get("/auth/user-cart");
  return data;
};

export const inc_cart_item = async (item_id, qty) => {
  const { data } = await $authHost.post(
    "/auth/cart-item-qty/" + item_id + "/" + qty,
    {}
  );
  return data;
};

export const remove_cart_item = async (item_id) => {
  const { data } = await $authHost.post(
    "/auth/cart-item-delete/" + item_id,
    {}
  );
  return data;
};

export const schools = async () => {
  const { data } = await $host.get("/schools");
  return data;
};

export const login = async (email, password) => {
  const { data } = await $host.post("/auth/login", { email, password });
  console.log(data.status);
  if(data.status=="success"){
    localStorage.setItem("token", data.token);
  }
  return data;
};

export const check = async () => {
  const { data } = await $authHost.get("/auth/check");

  localStorage.setItem("token", data.token);

  console.log(data.user);

  localStorage.setItem("user", data.user);

  return jwt_decode(data.token);
};

export const get_user_info = async () => {
  const { data } = await $authHost.get("/auth/profile");
  return data;
};

export const post_user_info = async (params) => {
  const { data } = await $authHost.post("/auth/profile", params);
  return data;
};

export const my_books = async () => {
  const { data } = await $authHost.get("/auth/my-books");
  return data;
};

export const my_course = async () => {
  const { data } = await $authHost.get("/auth/my-course");
  return data;
};

export const my_transaction = async (page) => {
  const { data } = await $authHost.get("/auth/transactions?page=" + page);
  return data;
};

export const my_exams = async () => {
  const { data } = await $authHost.get("/auth/my-exams");
  return data;
};

export const my_ubt_exams = async () => {
  const { data } = await $authHost.get("/auth/my-exam/ubt-exams");
  return data;
};

export const my_ubt_exam_item_caluelate = async (id) => {
  const { data } = await $authHost.get("/ubt/ubt-exam-calculate/" + id);
  return data;
};

export const lwa_exams = async (lwa) => {
  const { data } = await $authHost.get("/lwa/user-quiz/" + lwa);
  return data;
};

export const lwa_exam = async (lwa, token) => {
  const { data } = await $authHost.get(
    "/lwa/user-quiz-detail/" + lwa + "/" + token
  );
  return data;
};

export const my_creative_exams = async () => {
  const { data } = await $authHost.get("/auth/my-exam/creative-exams");
  return data;
};
export const my_creative_exam_item_caluelate = async (id) => {
  const { data } = await $authHost.get("/creative/creative-exam-calculate/" + id);
  return data;
};


export const creative_exam = async (id) => {
  const { data } = await $authHost.get(
    "/creative/creative-exam-result/" + id
  );
  return data;
};

export const translation = () => {
  const { t } = {
    "Welcome to React": "About us",
  };
  return t;
};

export const unfinished = async () => {
  const { data } = await $authHost.get("/unfinished");
  return data;
};

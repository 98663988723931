import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get_course_info } from "../../services/courseApi";
import { add_to_cart } from "../../services/userApi";
import swal from "@sweetalert/with-react";
import { observer } from "mobx-react-lite";
import { Context } from "../..";

const CourseListItem = observer((props) => {
  const [info, setInfo] = useState({});
  const { user } = useContext(Context);
  const fetch_info = async () => {
    await get_course_info(props.item.id).then((res) => setInfo(res));
  };
  const addToCart = async () => {
    await add_to_cart("course", props.item.id, {
      qty: 1,
      delivery_address: {},
    }).then((res) => {
      if (res.status == "error") {
        swal(i18next.t(res.message));
      }
      if (res.status == "success") {
        user.setCartCount(res.count);
      }
    });
  };
  useEffect(() => {
    if (props.item) {
      fetch_info();
    }
  }, [props]);
  return (
    <div className="book-item mb-4">
      {props.item.is_new ? (
        <span className="label status">{i18next.t("new")}</span>
      ) : (
        ""
      )}
      {props.item.discount > 0 ? (
        <span className="label discount">
          {" "}
          {props.item.discount} {i18next.t("tenge")}
        </span>
      ) : (
        ""
      )}

      <span className="play">
        <i className="bi bi-play-circle"></i>
      </span>
      <div className="action-cover">
        <button className="btn btn-info">
          <i className="bi-heart"></i>
        </button>
        <button className="btn btn-info" onClick={() => addToCart()}>
          <i className="bi-cart"></i>
        </button>
        <Link
          to={"/course-detail/" + props.item.id + "/play/" + info.init_video_id}
          className="btn btn-info"
        >
          <i className="bi bi-link-45deg"></i>
        </Link>
      </div>
      <div
        className="img img-h1"
        style={{ backgroundImage: `url(${info.image_cover})` }}
      ></div>
      <div className="info">
        <Link to={"/course-detail/" + props.item.id}>
          <div className="name">{props.item.title}</div>
        </Link>
        <p className="author">{props.item.author}</p>
        <span className="duration">
          {info.d} {i18next.t("minutes")}
        </span>
        <span className="list_count">
          {info.playlist} {i18next.t("videos")}
        </span>
      </div>
    </div>
  );
});
export default CourseListItem;

import i18next from "i18next";

import { Fragment, useContext, useEffect, useState } from "react";
import React from "react";
import { useHistory } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { Context } from "../..";

import MiniLoading from "../../components/MiniLoading";
import {
  get_lessons,
  get_lesson_classes,
  buy_quiz,
} from "../../services/lwaApi";
import { Alert, Button, Modal } from "react-bootstrap";
import swal from "sweetalert";

const Week = observer(() => {
  const { user } = useContext(Context);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [lessons, setLessons] = useState([]);

  const [classes, setClasses] = useState([]);
  const [lesson_class, setLessonClass] = useState({});

  const [loading, setLoading] = useState(false);
  const [inline_loading, setInlineLoading] = useState(false);
  const [error, setError] = useState(null);

  // const buy_quiz_action = () => {
  //   if (!user.IsAuth()) {
  //     swal(
  //       i18next.t("first login"),
  //       {
  //         buttons: {
  //           ok: i18next.t("Ok"),
  //         },
  //       },
  //       "warning"
  //     );
  //   }
  //   if (user.IsAuth()) {
  //     setInlineLoading(true);
  //     buy_quiz("nzm")
  //       .then((res) => {
  //         setInlineLoading(false);
  //         if (res.status === "success") {
  //           history.push("/bnl/quiz/nzm/" + res.token_tur1);
  //         }
  //         if (res.status === "error") {
  //           swal(
  //             i18next.t(res.message),
  //             {
  //               buttons: {
  //                 ok: i18next.t("Ok"),
  //               },
  //             },
  //             "warning"
  //           );
  //         }
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 401) {
  //           swal(
  //             i18next.t("first login"),
  //             {
  //               buttons: {
  //                 ok: i18next.t("Ok"),
  //               },
  //             },
  //             "warning"
  //           );
  //         }
  //       });
  //   }
  // };
  const fetch_data = async () => {
    setLoading(true);
    await get_lessons("week").then((res) => {
      setLessons(res);
      setLoading(false);
    });
  };

  const selectLesson = (l) => {
    setInlineLoading(true);
    setShow(true);
    get_lesson_classes("week", l.id).then((res) => {
      setClasses(res);
      setInlineLoading(false);
    });
  };

  const _buy_quiz = () => {
    setInlineLoading(true);
    buy_quiz("week", lesson_class.id)
      .then((res) => {
        if (res.status == "error") {
          setError(res.message);
        } else if (res.status == "success") {
          history.push("/week/quiz/" + res.token);
        } else {
          setError("error");
        }
        setInlineLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          swal(
            i18next.t("error"),
            i18next.t("first login"),

            "error"
          );
          setInlineLoading(false);
        }
      });
  };

  useEffect(() => {
    fetch_data();
  }, []);
  return (
    <Fragment>
      <div id="bnl" className="bg-gray">
        <div className="main-title">{i18next.t("week exam")}</div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h3 className="title">{i18next.t("lessons")}</h3>
            </div>
            <div className="col-xs-12">
              <div className="row">
                {loading ? (
                  <MiniLoading />
                ) : (
                  lessons.map((item, index) => (
                    <div key={index} className="col-xs-12 col-md-6">
                      <div className="d-flex lesson mb-3">
                        <div className="flex-fill icon">
                          <i className="bi-pencil-square" />
                        </div>
                        <div className="flex-fill info">
                          <p>{i18next.t("subject")}</p>
                          <h3>{item.name}</h3>
                        </div>
                        <div className="flex-fill lwa">
                          <button
                            className="btn btn-primary"
                            onClick={() => selectLesson(item)}
                          >
                            {i18next.t("select")}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            <Modal show={show}>
              <Modal.Header> {i18next.t("select class")}</Modal.Header>
              <Modal.Body>
                <div className="col-xs-12">
                  <div className="row classes">
                    {error != null ? (
                      <Alert variant="danger">{i18next.t(error)}</Alert>
                    ) : (
                      ""
                    )}
                    {inline_loading ? (
                      <MiniLoading />
                    ) : (
                      classes.map((item, index) => (
                        <div
                          key={index}
                          className={
                            lesson_class.id == item.id
                              ? " col-xs-12 col-md-4 hover selected"
                              : "col-xs-12 col-md-4 hover"
                          }
                          onClick={() => setLessonClass(item)}
                        >
                          <div className="d-flex class mb-3">
                            <div className="flex-fill icon">
                              <i className="bi-circle" />
                              <i className="bi-check-circle-fill" />
                            </div>
                            <div className="flex-fill info">
                              <h5>{item.name}</h5>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  {i18next.t("close")}
                </Button>

                <Button
                  variant="primary"
                  disabled={inline_loading || lesson_class.id == undefined}
                  onClick={() => _buy_quiz()}
                >
                  {inline_loading
                    ? i18next.t("loading ...")
                    : i18next.t("buy quiz")}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="bnl">
            <h3>{i18next.t("about_level_exam")}</h3>
            <p>{i18next.t("about_level_exam_text")}</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
});
export default Week;

import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React    from 'react';
import MiniLoading from "../../../components/MiniLoading";
import { get_bnl_exams } from "../../../services/bnlApi";
import Account from "../Account";
import BilExamItem from "./BilExamItem";
import { Card } from "react-bootstrap";


const BilExams = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    get_bnl_exams("bil").then((res) => {
      setData(res);
      setLoading(false);
    });
  }, []);
  return (
    <Fragment>
      <div className="main-title">{i18next.t("bil")}</div>
      <div id="NzmExams" className="container">
        <h3 className="title">{i18next.t("bil")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="exams" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              {loading ? (
                <MiniLoading />
              ) : (
                data.map((v, i) => {
                  return (
                    <Fragment>
                      
                      <div className="col-xs-12 col-md-6" key={i}>
                      {<h4>{v.token} <span style={{float:'right'}}>{v.created_at}</span></h4>}
                        <Card className="mb-3">
                          <Card.Header>{i18next.t("tur1")}</Card.Header>
                          {v.turs.filter(el=>el.tur==1).map((tur)=><BilExamItem item={tur} />)}
                          <Card.Header>{i18next.t("tur2")}</Card.Header>
                          {v.turs.filter(el=>el.tur==2).map((tur)=><BilExamItem item={tur} />)}
                        </Card>
                      </div>
                      
                    </Fragment>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BilExams;

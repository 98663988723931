import { $host } from ".";

export const get_ubt_lessons = async () => {
  const { data } = await $host.get("/ubt-lessons");
  return data;
};

export const page_data = async (key) => {
  const { data } = await $host.get("/page/" + key);
  return data;
};

export const newses = async () => {
  const { data } = await $host.get("/newses");
  return data;
};

export const news = async (key) => {
  const { data } = await $host.get("/news/" + key);
  return data;
};

export const latest_news = async (key) => {
  const { data } = await $host.get("/latest-news");
  return data;
};

export const top25 = async (quiz, range) => {
  const { data } = await $host.get("/report-top-25/" + quiz + "/" + range + "");
  return data;
};

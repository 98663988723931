import React, { Fragment, useEffect, useState } from "react";
import { my_transaction } from "../../services/userApi";
import Account from "./Account";
import GeneralEducation from "./GeneralEducation";
import i18next from "i18next";
import { Button, Table } from "react-bootstrap";
import MiniLoading from "../../components/MiniLoading";

const PaymentHistory = () => {
  const [data, setData] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const fetch_transaction = async (page) => {
    setLoading(true);
    await my_transaction(page).then((res) => {
      setPage(page);
      setData(res);
      setLoading(false);
    });
  };

  const next_page = () => {
    if (data.last_page > page) {
      fetch_transaction(page + 1);
    }
  };
  const prev_page = () => {
    if (data.current_page <= data.last_page) {
      fetch_transaction(page - 1);
    }
  };
  useEffect(() => {
    fetch_transaction(1);
  }, []);
  return (
    <Fragment>
      <div className="main-title">{i18next.t("transaction")}</div>
      <div id="PaymentHistory" className="container">
        <h3 className="title">{i18next.t("transaction")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="history" />
          </div>
          <div className="col-xs-12 col-md-8">
            <Table striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{i18next.t("amount")}</th>
                  <th>{i18next.t("created_at")}</th>
                  <th>{i18next.t("description")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <MiniLoading />
                ) : (
                  data.data.map((item, index) => (
                    <tr
                      key={item.id}
                      className={
                        item.status == "income"
                          ? "col-xs-12 col-md-6 income"
                          : "col-xs-12 col-md-6"
                      }
                    >
                      <td>{index}</td>
                      <td>{item.amount}</td>
                      <td>{item.created_at}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <div className="pagination">
              <Button
                variant="primary"
                onClick={() => (data.current_page == 1 ? null : prev_page())}
              >
                {i18next.t("prev")}
              </Button>
              <span>
                {data.current_page}/{data.last_page}
              </span>
              <Button
                variant="primary"
                onClick={() => (data.last_page == page ? null : next_page())}
              >
                {i18next.t("next")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PaymentHistory;

import { $authHost, $host } from ".";

export const get_lastest_courses = async () => {
  const { data } = await $host.get("/course/latest-course");
  return data;
};

export const get_course = async (id) => {
  const { data } = await $authHost.get("/course/video/" + id);
  return data;
};

export const get_course_info = async (id) => {
  const { data } = await $host.get("/course/video-info/" + id);
  return data;
};

export const get_categories = async () => {
  const { data } = await $host.get("/course/categories");
  return data;
};

export const buy_video = async (id) => {
  const { data } = await $authHost.post("/buy-video/" + id);
  return data;
};

export const get_questions = async (quiz_id, user_quiz_id) => {
  const { data } = await $authHost.get(
    "/course/video-quiz/" + quiz_id + "/" + user_quiz_id
  );
  return data;
};

export const get_result = async (quiz_id, user_quiz_id) => {
  const { data } = await $authHost.get(
      "/course/video-quiz-result/" + quiz_id + "/" + user_quiz_id
  );
  return data;
};
export const get_question = async (id, user_quiz_id) => {
  const { data } = await $authHost.get(
    "/course/video-quiz-question/" + id + "/" + user_quiz_id
  );
  return data;
};

export const answer_update = async (id, is_checked) => {
  const { data } = await $authHost.post(
    "/course/video-question-update/" + id + "/" + is_checked
  );
  return data;
};

export const post_finish = async (id) => {
  const { data } = await $authHost.post("/course/video-quiz-finish/" + id);
  return data;
};

export const get_courses = async (sort, category, page = 0) => {
  const { data } = await $host.get(
    "/course/videos/" +
      sort +
      (category ? "/" + category : "/0") +
      "?page=" +
      page
  );
  return data;
};

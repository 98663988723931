import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import MiniLoading from "../../components/MiniLoading";
import { get_user_info, post_user_info } from "../../services/userApi";
import Account from "./Account";

const Profile = () => {
  const [first_name, setFirstName] = useState(null);
  const [last_name, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const get_info = async () => {
    setLoading(true);
    await get_user_info().then((res) => {
      setFirstName(res.first_name);
      setLastName(res.last_name);
      setPhone(res.phone);
      setData(res);
      setLoading(false);
    });
  };
  const post_info = async (event) => {
    event.preventDefault();
    setLoading(true);
    await post_user_info({ first_name, last_name, phone }).then((res) => {
      if (res.status === "success") {
        setLoading(false);
        get_info();
      }
    });
  };
  useEffect(() => {
    get_info();
  }, []);
  return (
    <Fragment>
      <div className="main-title">{i18next.t("profile")}</div>
      <div id="profile" className="container">
        <h3 className="title">{i18next.t("edit_profile")}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account activate="profile" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row justify-content-center">
              <Card className="w-50 m-0 p-0">
                <Card.Header>{i18next.t("edit_profile")}</Card.Header>
                <Card.Body>
                  {error && <Alert variant="warning">{error}</Alert>}
                  {success && <Alert variant="success">{success}</Alert>}
                  <Form onSubmit={post_info}>
                    <Form.Group className="mb-3" controlId="unumber">
                      <Form.Label>{i18next.t("unumber")}</Form.Label>
                      <Form.Control
                        disabled={true}
                        defaultValue={data.unumber}
                        placeholder={i18next.t("unumber")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="first_name">
                      <Form.Label>{i18next.t("first_name")}</Form.Label>
                      <Form.Control
                        defaultValue={data.first_name}
                        type="text"
                        placeholder={i18next.t("first_name")}
                        onChange={(event) => setFirstName(event.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>{i18next.t("last_name")}</Form.Label>
                      <Form.Control
                        defaultValue={data.last_name}
                        type="text"
                        placeholder={i18next.t("last_name")}
                        onChange={(event) => setLastName(event.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>{i18next.t("Email")}</Form.Label>
                      <Form.Control
                        defaultValue={data.email}
                        disabled={true}
                        placeholder={i18next.t("Email")}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="phone">
                      <Form.Label>{i18next.t("phone_number")}</Form.Label>
                      <Form.Control
                        defaultValue={data.phone}
                        placeholder={i18next.t("phone_number")}
                        onChange={(event) => setPhone(event.target.value)}
                      />
                    </Form.Group>
                    {data.name && data.class_level ? (
                      <Form.Group className="mb-3" controlId="school">
                        <Form.Label>{i18next.t("school")}</Form.Label>
                        <Form.Control
                          defaultValue={
                            data.name +
                            " - " +
                            data.class_level +
                            i18next.t("class")
                          }
                          disabled={true}
                          placeholder={i18next.t("school")}
                        />
                      </Form.Group>
                    ) : (
                      ""
                    )}

                    <Button variant="primary" type="submit">
                      {i18next.t("save")}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Profile;

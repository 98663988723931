import i18next from "i18next";
import React from 'react';

const BnlLessonItem = (props) => {
  let item = props.item;
  return (
    <div className="d-flex lesson mb-3">
      <div className="flex-fill icon">
        <i className="bi-pencil-square" />
      </div>
      <div className="flex-fill info">
        <p>{i18next.t("subject")}</p>
        <h3>{item.name}</h3>
      </div>
      <div className="flex-fill q-num">
        <p className="label ">{i18next.t("questions")}</p>
        <h3 className="number mr-5">{item.numbers}</h3>
      </div>
    </div>
  );
};

export default BnlLessonItem;

import i18next from "i18next";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import Account from "../Account";
import { lwa_exams } from "../../../services/userApi";
import MiniLoading from "../../../components/MiniLoading";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";

const LwaExams = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { lwa } = useParams();
  const history = useHistory();
  const fetchExam = async () => {
    setLoading(true);
    await lwa_exams(lwa).then((res) => {
      setData(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchExam();
  }, []);
  return (
    <Fragment>
      <div className="main-title">{i18next.t("ubt")}</div>
      <div id="LwaExams" className="container">
        <h3 className="title">{i18next.t(lwa)}</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Account active="exams" />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              <div className="w-100">
                <ul style={{ display: "inline-flex", gap: "20px" }}>
                  <li>{i18next.t("Beginner")}</li>
                  <li>{i18next.t("Elementary")}</li>
                  <li>{i18next.t("Pre-Intermediate")}</li>
                </ul>
              </div>
              {loading ? (
                <MiniLoading />
              ) : (
                data.map((v, i) => {
                  return (
                    <div className="col-xs-12 col-md-6">
                      <div
                        className="education"
                        onClick={() =>
                          history.push("/user/exam/" + lwa + "/" + v.token)
                        }
                      >
                        <div className="icon">
                          <i className="bi bi-card-checklist" />
                        </div>
                        {v.lesson_name} <i className="bi bi-dot" />
                        {v.class_name}
                        <div className="point">
                          {parseInt((v.true_point * 100) / v.total_point)}%{" "}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LwaExams;

import i18next from "i18next";

import { Link } from "react-router-dom";
import React from "react";
import swal from "sweetalert";

const PlayListItem = (props) => {
  const timetostr = (s) => {
    let timeLeft = { hours: "00", minutes: "00", seconds: "00" };

    if (s > 0) {
      let h = Math.floor((s / (60 * 60)) % 24);
      let m = Math.floor((s / 60) % 60);
      let ss = Math.floor(s % 60);
      timeLeft = {
        hours: h > 9 ? h : "0" + h,
        minutes: m > 9 ? m : "0" + m,
        seconds: ss > 9 ? ss : "0" + ss,
      };
    } else {
      return null;
    }

    return timeLeft.hours + ":" + timeLeft.minutes + ":" + timeLeft.seconds;
  };
  return (
    <div
      className={
        "d-flex " + (props.item.code === "private" ? "private" : "public")
      }
    >
      <div className="flex-shrink-0">
        <img
          className="mr-3"
          src={props.item.image_cover}
          style={{ width: 120, height: 85 }}
          alt="Generic placeholder"
        />
        <i className="bi bi-file-earmark-lock2"></i>
      </div>
      <div className="flex-grow-1 m-0 p-0 ms-2">
        {
          props.item.code == "private" ? <Link onClick={()=>{
            {i18next.t("not paid course")}
          }}>
            <h5>{props.item.name}</h5>
          </Link>: <Link
          to={"/course-detail/" + props.course_id + "/play/" + props.item.id}
        >
          <h5>{props.item.name}</h5>
        </Link>
        }
        
        <p>
          <i className="bi bi-clock"></i> {timetostr(props.item.duration)}
          &nbsp; &nbsp;
          {props.item.is_done ? (
            <button
              className="btn btn-sm  btn-info"
              onClick={() => {
                props.callback_result(
                  props.item.quiz_id,
                  props.item.user_quiz_id
                );
              }}
            >
              {i18next.t("quiz_result")}: {props.item.total}%
            </button>
          ) : props.item.quiz_id ? (
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                props.callback(props.item.quiz_id, props.item.user_quiz_id);
              }}
            >
              {i18next.t("take_quiz")}
            </button>
          ) : (
            ""
          )}
        </p>
      </div>
    </div>
  );
};

export default PlayListItem;

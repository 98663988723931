import i18next from "i18next";
import React from "react";

const UbtMainLesson = (props) => {
  let data = props.data;
  return (
    <div className="row">
      {data.map((item, index) => (
        <div className="col-xs-12 col-md-4" key={index}>
          <div className={"p-5 main-item color" + (index + 1)}>
            <i className="bi-pencil-square mb-3"></i>
            <h3>{item.name}</h3>
            <p>{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UbtMainLesson;
